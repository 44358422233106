import i18n from 'i18next';
import { Application, Container } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../config';
import type { SlotId } from '../config/config';
import { EventTypes, GameMode, UserBonus } from '../global.d';
import {
  setBrokenGame,
  setIsDuringBigWinLoop,
  setIsRevokeThrowingError,
  setSlotConfig,
  setStressful,
} from '../gql/cache';
import { Logic } from '../logic';

import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BigWinContainer } from './bigWinPresentation/bigWinContainer';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import type { ViewContainer } from './components/ViewContainer';
import { PopupTypes, REELS_AMOUNT, eventManager } from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import MenuBtn from './controlButtons/menuBtn';
import SoundBtn from './controlButtons/soundBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import type { Block, ISlotData, ThorRewards } from './d';
import FadeArea from './fadeArea/fadeArea';
import { Foreground } from './foreground/foreground';
import GameView from './gameView/gameView';
import LightningBonus from './lightningBonus/lightningBonus';
import Logo from './logo/logo';
import Phoenix from './phoenix/phoenix';
import { PopupController } from './popups/PopupController';
import BuyFeaturePopup from './popups/buyFeaturePopup/buyFeaturePopup';
import BuyFeaturePopupConfirm from './popups/buyFeaturePopupConfirm/buyFeaturePopupConfirm';
import { LightningBonusEndPopup } from './popups/lightningBonusPopup/lightningBonusEndPopup';
import { LightningBonusPopup } from './popups/lightningBonusPopup/lightningBonusPopup';
import { SpecialShotBonusEndPopup } from './popups/specialShotBonusPopup/specialShotBonusEndPopup';
import { SpecialShotBonusPopup } from './popups/specialShotBonusPopup/specialShotBonusPopup';
import CascadeReelsContainer from './reels/reelsContainer/cascadeReelsContainer';
import type { ReelsContainer } from './reels/reelsContainer/reelsContainer';
import type Slot from './reels/slot';
import SafeArea from './safeArea/safeArea';
import SpecialShotBonus from './specialShotBonus/specialShotBonus';
import WinCountUpMessage from './winAnimations/winCountUpMessage';

class SlotMachine {
  public isStopped = false;

  public static initSlotMachine = (slotData: ISlotData): void => {
    SlotMachine.slotMachine = new SlotMachine(Logic.the.application, slotData);
  };

  public static the(): SlotMachine {
    return SlotMachine.slotMachine;
  }

  private static slotMachine: SlotMachine;

  private application: Application;

  private reelsContainer!: ReelsContainer & ViewContainer;

  private constructor(application: Application, slotConfig: ISlotData) {
    this.application = application;
    this.application.stage.sortableChildren = true;

    this.initSlotMachineListeners();
    this.buildSlotMachine(slotConfig);
  }

  private initSlotMachineListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setBrokenGame()) eventManager.emit(EventTypes.GAME_READY);
    });
    eventManager.addListener(EventTypes.THROW_ERROR, SlotMachine.handleError);
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      switch (Logic.the.controller.gameMode) {
        case GameMode.BASE_GAME:
          AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
          break;
        case GameMode.LIGHTNING_BONUS:
          AudioApi.play({ type: ISongs.BGM_LIGHT_Loop });
          break;
        case GameMode.SPECIAL_SHOT_BONUS:
          AudioApi.play({ type: ISongs.BGM_SHOT_Loop });
          break;
        default:
          AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
      }
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.BigWin_Loop });
      }
    });
  }

  private buildSlotMachine(_slotConfig: ISlotData): void {
    const reelLayout = setSlotConfig();

    const blocks: Block[] = reelLayout.data.features.generatedReelSet.map((e) => ({
      slotId: e.iconId as SlotId,
      rewardName: e.rewardName as ThorRewards,
    }));
    this.reelsContainer = this.getReelsContainer(blocks);

    const gameView = new GameView({
      reelsContainer: this.reelsContainer,
      winCountUpMessage: new WinCountUpMessage(),
    });
    const gameLogo = new Logo();
    const lightningBonusPopup = new LightningBonusPopup();
    const lightningBonusPopupEnd = new LightningBonusEndPopup();
    const specialShotBonusPopup = new SpecialShotBonusPopup();
    const specialShotBonusPopupEnd = new SpecialShotBonusEndPopup();
    const menuBtn = new MenuBtn();
    const soundBtn = new SoundBtn();
    const turboSpinBtn = new TurboSpinBtn();
    const spinBtn = new SpinBtn();
    const betBtn = new BetBtn();
    const autoplayBtn = new AutoplayBtn();
    const safeArea = new SafeArea();
    const phoenix = new Phoenix();

    this.buildBuyFeaturePopups();

    PopupController.the.registerPopup(PopupTypes.LIGHTNING_BONUS, lightningBonusPopup);
    PopupController.the.registerPopup(PopupTypes.LIGHTNING_BONUS_END, lightningBonusPopupEnd);

    PopupController.the.registerPopup(PopupTypes.SPECIAL_SHOT_BONUS, specialShotBonusPopup);
    PopupController.the.registerPopup(PopupTypes.SPECIAL_SHOT_BONUS_END, specialShotBonusPopupEnd);

    safeArea.addChild(gameView);
    safeArea.addChild(gameLogo);

    this.application.stage.addChild(
      new Background(),
      new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG),
      safeArea,
      new Foreground(),
      lightningBonusPopupEnd,
      lightningBonusPopup,
      specialShotBonusPopupEnd,
      specialShotBonusPopup,
      new BottomContainer(),
      new BigWinContainer(),
      new FadeArea(),
      menuBtn,
      soundBtn,
      turboSpinBtn,
      spinBtn,
      betBtn,
      autoplayBtn,
      phoenix,
      new LightningBonus(),
      new SpecialShotBonus(),
    );
  }

  private buildBuyFeaturePopups(): Container {
    const container = new Container();
    const buyFeaturePopup = new BuyFeaturePopup();
    const buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE, buyFeaturePopup);
    PopupController.the.registerPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, buyFeaturePopupConfirm);
    this.application.stage.addChild(new BuyFeatureBtn());
    this.application.stage.addChild(buyFeaturePopup);
    this.application.stage.addChild(buyFeaturePopupConfirm);

    return container;
  }

  private getReelsContainer(reelBlocks: Block[]) {
    return new CascadeReelsContainer(reelBlocks);
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  public onBrokenGame(bonus: UserBonus): void {
    eventManager.emit(EventTypes.BROKEN_GAME, bonus);
    eventManager.emit(EventTypes.GAME_READY);
  }

  public getSlotAt(_x: number, y: number): Slot | null {
    return this.reelsContainer.reel.slots[y as number]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }
}

export default SlotMachine;
