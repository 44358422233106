import MultiStyleText, { TextStyleSet } from 'pixi-multistyle-text';
import { ITextStyle, Text, TextStyle } from 'pixi.js';

export class TextField {
  text: Text;

  height: number;

  width: number;

  constructor(
    txt = '',
    width: number,
    height: number,
    style?: Partial<ITextStyle> | TextStyle,
    isMultiText = false,
    multiTextStyle?: TextStyleSet,
  ) {
    this.text = (
      isMultiText && multiTextStyle ? new MultiStyleText(txt, multiTextStyle) : new Text(txt, style)
    ) as Text;
    this.width = width;
    this.height = height;
  }

  public update(width: number, height: number): void {
    this.width = width;
    this.height = height;
    this.text.scale.set(1);
    const scaleFactor = Math.min(Math.min(this.height / this.text.height, this.width / this.text.width), 1);
    this.text.scale.set(scaleFactor);
  }

  public getText(): Text {
    this.text.scale.set(1);
    const scaleFactor = Math.min(Math.min(this.height / this.text.height, this.width / this.text.width), 1);
    this.text.scale.set(scaleFactor);
    return this.text;
  }

  public forceScale(factor: number): void {
    this.text.scale.set(factor);
  }

  public setText(text: string): void {
    this.text.text = text;
    this.text.scale.set(1);
    const scaleFactor = Math.min(Math.min(this.height / this.text.height, this.width / this.text.width), 1);
    this.text.scale.set(scaleFactor);
  }

  public setStyle(style: Partial<ITextStyle>): void {
    this.text.scale.set(1);
    this.text.style = style;
    const scaleFactor = Math.min(Math.min(this.height / this.text.height, this.width / this.text.width), 1);
    this.text.scale.set(scaleFactor);
  }
}
