import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { ViewContainer } from '../../components/ViewContainer';

class BuyFeaturePopupBackgroundAnimated extends ViewContainer {
  private popupBg: Spine;

  constructor() {
    super();
    this.popupBg = this.initPopupBg();
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
  }

  private initPopupBg(): Spine {
    const bg = new Spine(Loader.shared.resources['buyFeaturePopups']!.spineData!);
    bg.state.setAnimation(0, 'buy_feature_popup_dark_to_light', true);
    bg.x = 640;
    bg.y = 465;
    bg.scale.set(1.35);
    return bg;
  }

  public handleChangeBg(name: string): void {
    this.popupBg!.state.setAnimation(0, name, true);
  }
}

export default BuyFeaturePopupBackgroundAnimated;
