import { Container, Sprite, Texture, isMobile } from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { lightningBonusMultiplierStyle } from '../config';

const multipliersMap: { [key in string]: string } = {
  x2: Resources.getSource(ResourceTypes.x2),
  x3: Resources.getSource(ResourceTypes.x3),
  x5: Resources.getSource(ResourceTypes.x5),
  x10: Resources.getSource(ResourceTypes.x10),
  x20: Resources.getSource(ResourceTypes.x20),
  x30: Resources.getSource(ResourceTypes.x30),
  x50: Resources.getSource(ResourceTypes.x50),
  x70: Resources.getSource(ResourceTypes.x70),
  x100: Resources.getSource(ResourceTypes.x100),
};

interface CoordinatesData {
  portrait: {
    initialCoordinate: { x: number; y: number };
    targetCoordinate: { x: number; y: number };
  };
  landscape: {
    initialCoordinate: { x: number; y: number };
    targetCoordinate: { x: number; y: number };
  };
}

class LightningBonusMultiplier extends ViewContainer {
  override name = 'LightningBonusMultiplier';

  protected container: Container;

  private sprite!: Sprite;

  private text!: TextField;

  public isDisabled!: boolean;

  public resizedWidth: number;

  public resizedHeight: number;

  public multiplier: number;

  public newMultiply!: number;

  public isFirst = false;

  public isFinal = false;

  public coordinatesData: CoordinatesData;

  get getLightningBonusMultiplier(): Container {
    return this.container;
  }

  get getText(): TextField {
    return this.text;
  }

  constructor(
    multiplier: number,
    name: string,
    isFirst = false,
    isFinal = false,
    initWidth: number,
    initHeight: number,
    coordinatesData?: CoordinatesData,
  ) {
    super();
    this.name = name;
    this.isFirst = isFirst;
    this.isFinal = isFinal;
    this.coordinatesData = coordinatesData!;
    this.resizedWidth = initWidth;
    this.resizedHeight = initHeight;
    this.container = new Container();
    this.multiplier = multiplier;
    this.initMultiplierAmount();
    if (isFinal) {
      this.container.addChild(this.text.text);
    } else {
      this.container.addChild(this.sprite);
    }
    this.setMultiplierScale();
    this.updateMultiplierPosition();
  }

  private initMultiplierAmount(): void {
    if (this.isFinal) {
      this.text = new TextField(`x${this.multiplier}`, 300, 300, lightningBonusMultiplierStyle);
      this.text.text.anchor.set(0.5, 0.5);
    } else {
      this.sprite = new Sprite(Texture.from(multipliersMap[`x${this.multiplier}` as string]!));
    }
  }

  private updateMultiplierPosition(): void {
    const deviceOrientation = this.resizedWidth > this.resizedHeight ? 'landscape' : 'portrait';
    if (!this.coordinatesData) return;
    if (this.container.visible) {
      this.container.position.set(
        this.coordinatesData[deviceOrientation as keyof CoordinatesData].targetCoordinate.x,
        this.coordinatesData[deviceOrientation as keyof CoordinatesData].targetCoordinate.y,
      );
    } else {
      this.container.position.set(
        this.coordinatesData[deviceOrientation as keyof CoordinatesData].initialCoordinate.x,
        this.coordinatesData[deviceOrientation as keyof CoordinatesData].initialCoordinate.y,
      );
    }
  }

  public setMultiplierScale(): void {
    const scale = Math.min(
      1,
      this.resizedWidth <= this.resizedHeight ? this.resizedWidth / 800 : this.resizedHeight / 800,
    );
    if (this.isFinal) {
      this.container.x = this.resizedWidth / 2;
      this.container.y = this.resizedHeight / 2;
      this.container.scale.set(scale);
    } else {
      this.sprite.scale.set(isMobile.any ? 0.5 : scale);
      this.sprite.x = -(this.sprite.width / 2);
      this.sprite.y = -(this.sprite.height / 2);
    }
  }

  protected override resize(width: number, height: number): void {
    this.resizedHeight = height;
    this.resizedWidth = width;
    this.setMultiplierScale();
    this.updateMultiplierPosition();
  }
}

export default LightningBonusMultiplier;
