import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, LightningEntryAnimation } from '../../global.d';
import { setGuaranteeAmount, setIsTurboSpin, setWPower } from '../../gql/cache';
import { eventManager } from '../config';
import { ThorMovements } from '../d';

class Thor {
  protected thor: Spine;

  private idleType!: ThorMovements;

  private forcedStopped = false;

  constructor() {
    this.thor = this.initThor();
    this.initThorListeners();
    this.setThorAnimation(ThorMovements.idle, true);
  }

  get getThor(): Spine {
    return this.thor;
  }

  private initThor(): Spine {
    const thor = new Spine(Loader.shared.resources['thor']!.spineData!);
    thor.name = 'Thor';
    const isTurboSpin = setIsTurboSpin();
    thor.state.timeScale = isTurboSpin ? 1.5 : 1;
    return thor;
  }

  private initThorListeners(): void {
    this.thor.state.addListener({
      complete: (entry: LightningEntryAnimation) => {
        switch (entry.animation.name) {
          // This might not be relevat.
          case ThorMovements.attack:
          case ThorMovements.guaranteeAttack:
          case ThorMovements.wPowerAttack:
          case ThorMovements.guaranteePlusWPowerAttack:
            this.setThorAnimation(this.idleType, true);
            break;
          case ThorMovements.wPowerPlusGuaranteeIn:
          case ThorMovements.guaranteePlusWPowerIn:
            eventManager.emit(EventTypes.THOR_AFTER_SUPER_POWER_IN);
            this.setThorAnimation(ThorMovements.guaranteePlusWPowerLoop, true);
            break;
          case ThorMovements.guaranteeIn:
            eventManager.emit(EventTypes.THOR_AFTER_SUPER_POWER_IN);
            this.setThorAnimation(ThorMovements.guaranteeIdle, true);
            break;
          case ThorMovements.wPowerIn:
            eventManager.emit(EventTypes.THOR_AFTER_SUPER_POWER_IN);
            this.setThorAnimation(ThorMovements.wPowerIdle, true);
            break;
          case ThorMovements.requestBlocks:
            eventManager.emit(EventTypes.THOR_AFTER_SUPER_POWER_IN);
            this.setThorAnimation(ThorMovements.idle, true);
            break;
          default:
            break;
        }
      },
      event: (_entry, event) => {
        switch (event.data.name) {
          case 'landing_animation':
            eventManager.emit(EventTypes.THOR_REQUEST_BLOCKS_LIGHTNING);
            break;
          case 'blocks_destruction_animation':
            eventManager.emit(EventTypes.THOR_AFTER_ATTACK);
            break;
          default:
        }
      },
    });

    eventManager.addListener(EventTypes.TOGGLE_TURBO_SPIN, (isTurboSpin: boolean) => {
      if (this.thor.state.timeScale === 2) return;
      this.thor.state.timeScale = isTurboSpin ? 1.5 : 1;
    });
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.forcedStopped = true;
    });

    // Default listeners
    eventManager.addListener(EventTypes.THOR_ATTACK, (attackType: ThorMovements, idleType: ThorMovements) =>
      this.attack(attackType, idleType),
    );
    eventManager.addListener(EventTypes.THOR_IDLE, () => {
      this.setThorAnimation(ThorMovements.idle, true);
      this.setTurboSpinTimescale();
    });

    // W_POWER listeners
    eventManager.addListener(EventTypes.THOR_W_POWER_IN, () => this.wPowerIn());
    eventManager.addListener(EventTypes.THOR_W_POWER_OUT, () => this.wPowerOut());

    // Guarantee win listeners
    eventManager.addListener(EventTypes.THOR_GUARANTEE_IN, () => {
      AudioApi.play({ type: ISongs.INVINCIBLE_START, stopPrev: true });

      this.guaranteeIn();
    });
    eventManager.addListener(EventTypes.THOR_GUARANTEE_OUT, () => {
      AudioApi.play({ type: ISongs.INVINCIBLE_END, stopPrev: true });

      this.guaranteeOut();
    });
    eventManager.addListener(EventTypes.THOR_REQUEST_BLOCKS, () => this.requestBlocks());
    eventManager.addListener(EventTypes.RESET_THOR_ANIMATION_SPEED, () => {
      this.forcedStopped = false;
      const isTurboSpin = setIsTurboSpin();
      this.thor.state.timeScale = isTurboSpin ? 1.5 : 1;
    });
  }

  private setThorAnimation(animation: ThorMovements, loop: boolean): void {
    this.thor.state.setAnimation(0, animation, loop);
  }

  private attack(attack: ThorMovements, idle: ThorMovements): void {
    if (this.forcedStopped) {
      this.thor.state.timeScale = 2;
      this.forcedStopped = false;
    }
    AudioApi.play({
      type: ISongs.SWING,
      stopPrev: true,
    });
    this.thor.state.setAnimation(0, attack, false);

    this.idleType = idle;
  }

  // WPower state
  private wPowerIn(): void {
    AudioApi.play({ type: ISongs.POWER_UP, stopPrev: true });

    const currentGuaranteeAmount = setGuaranteeAmount();
    if (currentGuaranteeAmount) {
      this.guaranteeWPowerIn();
    } else {
      this.thor.state.setAnimation(0, ThorMovements.wPowerIn, false);
    }
  }

  private wPowerOut(): void {
    const currentGuaranteeAmount = setGuaranteeAmount();
    if (currentGuaranteeAmount) {
      this.guaranteeWPowerOut();
    } else {
      this.thor.state.setAnimation(0, ThorMovements.wPowerOut, false);
    }
  }

  private guaranteeWPowerIn(): void {
    this.thor.state.setAnimation(0, ThorMovements.guaranteePlusWPowerIn, false);
  }

  private guaranteeWPowerOut(): void {
    this.thor.state.setAnimation(0, ThorMovements.guaranteePlusWPowerOut, false);
  }

  // Guarantee state
  private guaranteeIn(): void {
    AudioApi.play({ type: ISongs.POWER_UP, stopPrev: true });

    const wPower = setWPower();
    if (wPower) {
      this.wPowerGuaranteeIn();
    } else {
      this.thor.state.setAnimation(0, ThorMovements.guaranteeIn, false);
    }
  }

  private wPowerGuaranteeIn(): void {
    this.thor.state.setAnimation(0, ThorMovements.wPowerPlusGuaranteeIn, false);
  }

  private wPowerGuaranteeOut(): void {
    this.thor.state.setAnimation(0, ThorMovements.wPowerPlusGuaranteeOut, false);
  }

  private guaranteeOut(): void {
    const wPower = setWPower();
    if (wPower) {
      this.wPowerGuaranteeOut();
    } else {
      this.thor.state.setAnimation(0, ThorMovements.guaranteeOut, false);
    }
  }

  private requestBlocks(): void {
    this.thor.state.setAnimation(0, ThorMovements.requestBlocks, false);
  }

  private setTurboSpinTimescale(): void {
    const isTurboSpin = setIsTurboSpin();
    if (this.thor.state.timeScale === 2) return;
    this.thor.state.timeScale = isTurboSpin ? 1.5 : 1;
  }
}

export default Thor;
