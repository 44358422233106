const FRAME_WIDTH = 1030;
export const BUY_FEATURE_POPUP_CONFIG = {
  title: {
    x: FRAME_WIDTH / 2,
    top: 195,
  },
  plusMinusBtn: {
    top: 916,
    plusBtn: {
      left: 791,
    },
    minusBtn: {
      left: 154,
    },
  },
  cancelOkBtn: {
    top: 1117,
    cancelBtn: {
      left: 154,
    },
    okBtn: { left: 571 },
  },
  buyFeaturePerGame: {
    top: 863,
    left: FRAME_WIDTH / 2,
  },
  betValue: {
    left: FRAME_WIDTH / 2,
    y: 945,
    top: 955,
  },
  totalBetBackplate: {
    top: 914,
    left: 268,
  },
  bonusTotalCost: {
    top: 716,
    lightning: {
      x: 287,
    },
    specialShot: { x: 726 },
  },
};
