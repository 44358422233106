import { Sprite, Texture, isMobile } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import type Animation from '../../animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';

import type { SpecialShotBonusEndPopup } from './specialShotBonusEndPopup';

class SpecialShotBonusEndPopupBackgroundStatic extends ViewContainer {
  private popupBg: Sprite;

  private specialShotBonusEndPopup: SpecialShotBonusEndPopup;

  public fadeOutAnimation: Animation;

  public fadeInAnimation: Animation;

  constructor(popup: SpecialShotBonusEndPopup) {
    super();
    this.specialShotBonusEndPopup = popup;
    this.popupBg = this.initPopupBg();
    this.fadeInAnimation = this.initFadeInAnimation();
    this.fadeOutAnimation = this.initFadeOutAnimation();
    this.addChild(this.popupBg);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.bonusGamePopup));
    popupBg.anchor.set(0.5);
    popupBg.x = 0;
    popupBg.y = 0;

    return popupBg;
  }

  public handleChangeBg(texture: string): void {
    this.popupBg.texture = Texture.from(texture);
  }

  private initFadeInAnimation(): Animation {
    return new Tween({
      object: this.popupBg,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 300,
    });
  }

  private initFadeOutAnimation(): Animation {
    return new Tween({
      object: this.popupBg,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: 300,
    });
  }

  public show(): void {
    this.specialShotBonusEndPopup.visible = true;
    this.specialShotBonusEndPopup.backgroundFadeInAnimation.start();
    this.specialShotBonusEndPopup.fadeInAnimation.start();
    this.specialShotBonusEndPopup.fadeInAnimation.addOnComplete(() => {});
    this.fadeInAnimation.start();
  }

  public hide(): void {
    this.fadeOutAnimation.start();
    this.specialShotBonusEndPopup.backgroundFadeOutAnimation.start();
    this.specialShotBonusEndPopup.fadeOutAnimation.start();
    this.specialShotBonusEndPopup.fadeOutAnimation.addOnComplete(() => {
      eventManager.emit(EventTypes.END_SPECIAL_SHOT_BONUS);
      this.specialShotBonusEndPopup.visible = false;
    });
  }

  public override resize(width: number, height: number): void {
    const landscape = height < width;
    if (isMobile.any) {
      this.scale.set(Math.min(1, landscape ? height / 1000 : width / 600));
    } else {
      this.scale.set(Math.min(1, landscape ? height / 1000 : width / 1000));
    }
  }
}

export default SpecialShotBonusEndPopupBackgroundStatic;
