import type React from 'react';
import { useTranslation } from 'react-i18next';

import Resources from '../../../utils/resources';
import styles from '../info.module.scss';

type Props = { title: string; desc: string; src: string };
export const InfoCard: React.FC<Props> = ({ title, desc, src }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles['card']}`}>
      <div>
        <img src={Resources.getSource(src)} alt="W-Power" />
        <h3 className={`${styles['p']} ${styles['left']}`}>
          <div dangerouslySetInnerHTML={{ __html: t(title) }} />
        </h3>
      </div>

      <div>
        <p className={`${styles['p']} ${styles['left']}`} dangerouslySetInnerHTML={{ __html: t(desc) }} />
      </div>
    </div>
  );
};
