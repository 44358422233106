import type { Spine } from 'pixi-spine';

import type { SlotId } from '../config';
import type { FeatureState, GeneratedReelSet, ReelSet, UserBonus } from '../global';

import type { ViewContainer } from './components/ViewContainer';
import type { ReelsContainer } from './reels/reelsContainer/reelsContainer';
import type WinCountUpMessage from './winAnimations/winCountUpMessage';

export interface ISlotMachine {
  transparent: boolean;
  width: number;
  height: number;
}

export interface IPlayerSettingsMinMax {
  min: number;
  max: number;
}

export interface IPlayerSettings {
  betAmount: IPlayerSettingsMinMax;
  coinValue: {
    EUR: IPlayerSettingsMinMax;
    FUN: IPlayerSettingsMinMax;
  };
}

export interface ISlotData {
  id: string;
  lineSets: LineSet[];
  clientSettings: {
    coinAmounts: {
      default: number[];
      quick: number[];
    };
    coinValues: {
      code: string;
      variants: number[];
    }[];
    features: Features[];
    autoplay: {
      options: number[];
      conditions: {
        stopOnAnyWin: {
          enabled: boolean;
        };
        stopIfFeatureIsWon: {
          enabled: boolean;
        };
        stopIfSingleWinExceeds: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceDecreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
        stopIfBalanceIncreasesBy: {
          enabled: boolean;
          multipliers: number[];
        };
      };
    };
  };
  icons: Icon[];
  previewImage: string;
  reels: ReelSet[];
  data: {
    bonuses: UserBonus[];
    features: {
      generatedReelSet: GeneratedReelSet[];
    };
  };
  settings: {
    betLines: {
      min: number;
      max: number;
    };
    playerSettings: IPlayerSettings;
  };
}

export interface Features {
  id: string;
  enabled: boolean;
}

export interface LineSet {
  id: string;
  slotId: string;
  lines: number[];
  coinAmountMultiplier: number;
}

export interface IGameContainer {
  reelsContainer: ReelsContainer & ViewContainer;
  winCountUpMessage: WinCountUpMessage;
}

export type Combos = IconCombo[] | undefined;

export interface Icon {
  combos?: Combo[];
  id: SlotId;
  payoffType: PayoffType;
  type: IconType;
  rewardIcon: string;
}

export interface Block {
  slotId: SlotId;
  rewardName: ThorRewards;
}

export enum IconType {
  EMPTY = 'EMPTY',
  REGULAR = 'REGULAR',
  SCATTER = 'SCATTER',
  WILD = 'WILD',
}

export enum PayoffType {
  LTR = 'LTR',
  RTL = 'RTL',
  ANY = 'ANY',
  ADJACENT = 'ADJACENT',
}
export enum RewardType {
  COINS = 'COINS',
  BONUS = 'BONUS',
}
export interface Reward {
  bonusId: string | null;
  count: number | null;
  multiplier: number | null;
  type: RewardType;
}
export interface IconCombo {
  pattern: string;
  multiplier: number;
}
export interface Combo {
  rewards: Reward[];
  pattern: string;
  payoffType: string;
  type: string;
}
export interface BuyFeatureConfirmProps {
  totalCost: string;
  coinAmount: number;
  featureState: FeatureState;
}
export interface AnimatedPopupProps {
  spine: Spine;
  inAnimationName: string;
  idleAnimationName: string;
  endAnimationName: string;
}

export enum ThorMovements {
  idle = 'idle',
  attack = 'attack',
  wPowerIn = 'w_power_in',
  wPowerOut = 'w_power_out',
  wPowerIdle = 'w_power_idle',
  wPowerAttack = 'w_power_attack',
  guaranteeIn = 'invincible_in',
  guaranteeOut = 'invincible_out',
  guaranteeIdle = 'invincible_loop',
  guaranteeAttack = 'invincible_attack',
  guaranteePlusWPowerAttack = 'invincible|w_power_attack',
  guaranteePlusWPowerIn = 'invincible|w_power_in',
  guaranteePlusWPowerOut = 'invincible|w_power_out',
  guaranteePlusWPowerLoop = 'invincible|w_power_loop',
  wPowerPlusGuaranteeIn = 'w_power|invincible_in',
  wPowerPlusGuaranteeOut = 'w_power|invincible_out',
  requestBlocks = 'landing_animation',
}

export enum LightningThorMovements {
  idle = 'idle',
  idle_2 = 'idle_2',
  in = 'in',
}

export enum SpecialThorMovements {
  collectEnergyIn = 'collect_energy_in_ss',
  collectEnergyInLoop = 'collect_energy_loop_ss',
  getReadyIn = 'get_ready_in_ss',
  getReadyInGreen = 'get_ready_in_ss_green',
  getReadyInRed = 'get_ready_in_ss_red',
  getReadyInYellow = 'get_ready_in_ss_yellow',
  getReadyLoop = 'get_ready_loop_ss',
  getReadyLoopGreen = 'get_ready_loop_ss_green',
  getReadyLoopRed = 'get_ready_loop_ss_red',
  getReadyLoopYellow = 'get_ready_loop_ss_yellow',
  defaultThreshold = 'get_ready_pauses_ss',
  yellowThreshold = 'get_ready_pauses_ss_yellow',
  greenThreshold = 'get_ready_pauses_ss_green',
  redThreshold = 'get_ready_pauses_ss_red',
  shotIn = 'shot_in_ss',
  shotInGreen = 'shot_in_ss_green',
  shotInRed = 'shot_in_ss_red',
  shotInYellow = 'shot_in_ss_yellow',
  shotInLoop = 'shot_loop_ss',
}

export type ThorPowers = 'D_W_POWER' | 'LIGHTNING_BONUS' | 'SPECIAL_SHOT';

export type ThorRewards = ThorPowers | string;
export type PopupProps = BuyFeatureConfirmProps | '';
