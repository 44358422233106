import type { Spine } from 'pixi-spine';
import { Container, isMobile } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { Track } from '../animations/track';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import type { IGameContainer } from '../d';
import { GameProgress } from '../gameProgress/gameProgress';
import type { ReelsContainer } from '../reels/reelsContainer/reelsContainer';
import Thor from '../thor/thor';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';
import { CascadeWinSlotsPresentation } from '../winAnimations/winSlotsPresentation/cascadeWinSlotsPresentation';

import GameReplay from './gameReplay';

class GameView extends ViewContainer {
  public reelsContainer: ReelsContainer & ViewContainer;

  public slotsContainer: Container;

  public winCountUpMessage: WinCountUpMessage;

  public thor: Spine;

  public gameProgress: Container;

  public gameReplay: Container;

  public isLandscapeMode!: boolean;

  public resizedWidth = 0;

  public resizedHeight = 0;

  public isLeftHandMode = false;

  public track: Track;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.slotsContainer = new Container();
    this.slotsContainer.interactive = true;
    this.slotsContainer.y = -555;
    this.slotsContainer.x = -275;
    this.thor = this.initThor();
    this.gameReplay = new GameReplay();
    this.gameProgress = this.initGameProgress();
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(new CascadeWinSlotsPresentation());
    this.track = new Track();
    this.addChild(this.slotsContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.thor);
    this.addChild(this.gameReplay);
    this.addChild(this.gameProgress);
    this.addChild(this.track);

    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
    });

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  private initGameProgress(): Container {
    const gameProgress = new GameProgress();
    gameProgress.x = 80;
    gameProgress.y = -330;
    return gameProgress;
  }

  private initThor(): Spine {
    const thor = new Thor().getThor;
    thor.x = 60;
    thor.y = 60;
    thor.scale.set(1.1);
    return thor;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.thor.visible = true;
        break;
      case GameMode.LIGHTNING_BONUS:
      case GameMode.SPECIAL_SHOT_BONUS:
        this.thor.visible = false;
        break;
      default:
        this.thor.visible = true;
    }
  }

  private resizeGameContainer(gameContainerSizes: {
    appWidth: number;
    appHeight: number;
    gameContainerWidth: number;
    gameContainerHeight: number;
    underGameContainerSpaceHeight: number;
  }): void {
    const isLandscape = gameContainerSizes.appWidth >= gameContainerSizes.appHeight;
    const isPortrait = gameContainerSizes.appHeight > gameContainerSizes.appWidth;

    const isDesktop = !isMobile.any;
    const isMobilePort = isMobile.any && isPortrait;
    const isMobileLand = isMobile.any && isLandscape;

    this.x = gameContainerSizes.gameContainerWidth / 2;

    if (isDesktop) {
      this.scale.set(gameContainerSizes.gameContainerHeight / 1080);
      this.y = gameContainerSizes.gameContainerHeight / 2;
    }

    if (isMobileLand) {
      this.scale.set(gameContainerSizes.gameContainerHeight / 790);
      this.y = gameContainerSizes.gameContainerHeight / 2.2;
    }

    if (isMobilePort) {
      this.scale.set(gameContainerSizes.gameContainerHeight / 790);
      this.y = gameContainerSizes.gameContainerHeight / 1.5;
    }

    this.gameProgress.x = gameContainerSizes.appWidth < 350 ? 50 : 80;
  }
}

export default GameView;
