export const infoHammerFeatureDescs1 = [
  'infoHammerFeatureDesc_1_1',
  'infoHammerFeatureDesc_1_2',
  'infoHammerFeatureDesc_1_3',
  'infoHammerFeatureDesc_1_4',
];

export const infoHammerFeatureDescs2 = [
  'infoHammerFeatureDesc_2_1',
  'infoHammerFeatureDesc_2_2',
  'infoHammerFeatureDesc_2_3',
  'infoHammerFeatureDesc_2_4',
  'infoHammerFeatureDesc_2_5',
];

export const infoPowerups = [
  {
    title: 'powerup_wpower_title',
    desc: 'powerup_wpower_desc',
  },
  {
    title: 'powerup_invincible_title',
    desc: 'powerup_invincible_desc',
  },
  {
    title: 'powerup_multiplier_title',
    desc: 'powerup_multiplier_desc',
  },
  {
    title: 'powerup_lightning_bonus_title',
    desc: 'powerup_lightning_bonus_desc',
  },
  {
    title: 'powerup_special_shot_bonus_title',
    desc: 'powerup_special_shot_bonus_desc',
  },
];

export const infoBonusFeatureDescs = ['infoBonusFeatureDesc_1'];

export const infoLightningBonusFeatureDescs = [
  'infoLightningBonusFeatureDesc_1',
  'infoLightningBonusFeatureDesc_2',
  'infoLightningBonusFeatureDesc_3',
  'infoLightningBonusFeatureDesc_4',
];

export const infoSpecialShotBonusFeatureDescs = [
  'infoSpecialShotBonusFeatureDesc_1',
  'infoSpecialShotBonusFeatureDesc_2',
  'infoSpecialShotBonusFeatureDesc_3',
];

export const infoMaxWinFeatureDescs = ['infoMaxWinFeatureDesc_1', 'infoMaxWinFeatureDesc_2'];

export const infoFeatureBuy = [
  'infoFeatureBuyDesc_1',
  'infoFeatureBuyDesc_2',
  'infoFeatureBuyDesc_3',
  'infoFeatureBuyDesc_6',
];
