import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setAnticipation, setBetResult } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { checkPhoenixAnticipation, getBetResult } from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { buyFeatureClickTextStyle, buyFeatureHoverTextStyle, buyFeatureTextStyle, eventManager } from '../config';

class SpecialShotBonusButton extends ViewContainer {
  public override name = 'SpecialShotBonusButton';

  private btn: Sprite;

  private animation: Spine;

  private text: TextField;

  public isDisabled!: boolean;

  public resizedWidth: number;

  public resizedHeight: number;

  public clicked = false;

  constructor(width: number, height: number) {
    super();
    this.text = this.initSpecialShotBonusText();
    this.btn = this.initSpecialShotPushBtn();
    this.animation = this.initButtonAnimation();
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
    this.addChild(this.btn);
    this.visible = false;

    eventManager.addListener(EventTypes.SPACEKEY_CLICKED, () => {
      if (this.visible) {
        this.onClick();
      }
    });
  }

  private initButtonAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['lightningBonusButton']!.spineData!);
    this.btn.addChild(animation);
    return animation;
  }

  private initSpecialShotPushBtn(): Sprite {
    const btn = new Sprite(Texture.from(ResourceTypes.pushBlueDefault));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.btn.texture = Texture.from(ResourceTypes.pushBlueHover);

      this.text.text.style = buyFeatureHoverTextStyle;
    });
    btn.addListener('mouseout', () => {
      this.btn.texture = Texture.from(ResourceTypes.pushBlueDefault);

      this.text.text.style = buyFeatureTextStyle;
    });
    btn.addListener('mousedown', () => {
      this.btn.texture = Texture.from(ResourceTypes.pushBluePressed);

      this.text.text.style = buyFeatureClickTextStyle;
    });
    btn.addListener('mouseup', () => {
      this.btn.texture = Texture.from(ResourceTypes.pushBlueDefault);

      this.text.text.style = buyFeatureTextStyle;
    });
    return btn;
  }

  private initSpecialShotBonusText(): TextField {
    const text = new TextField(i18n.t<string>('lightningBonusButtonPush'), 150, 300, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);

    return text;
  }

  public onClick(): void {
    if (this.clicked) return;
    this.clicked = true;
    AudioApi.play({ type: ISongs.PUSH_DISP1, stopPrev: true });

    const showPhoenix = checkPhoenixAnticipation(getBetResult(setBetResult()));
    if (showPhoenix) {
      this.visible = false;
      setAnticipation(true);
      eventManager.emit(EventTypes.PHOENIX_START);
      eventManager.once(EventTypes.PHOENIX_END, () => {
        setAnticipation(false);
        eventManager.emit(EventTypes.SPECIAL_SHOT_PUSH_CLICKED);
      });
    } else {
      this.visible = false;
      eventManager.emit(EventTypes.SPECIAL_SHOT_PUSH_CLICKED);
    }
  }

  protected override resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    const isLandscapeMode = this.resizedHeight < this.resizedWidth;
    const scale = isLandscapeMode ? this.resizedHeight / 1000 : this.resizedWidth / 1000;
    this.scale.set(Math.min(scale));
    this.x = this.resizedWidth / 2;
    this.y = (this.resizedHeight * 3) / 4;
  }
}

export default SpecialShotBonusButton;
