import { Sprite, Texture, isMobile } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { setBonusGameDataRequested } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import type Animation from '../../animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';

import type { LightningBonusPopup } from './lightningBonusPopup';

class LightningBonusPopupBackgroundStatic extends ViewContainer {
  private popupBg: Sprite;

  private lightningBonusPopup: LightningBonusPopup;

  public fadeOutAnimation: Animation;

  public fadeInAnimation: Animation;

  constructor(popup: LightningBonusPopup) {
    super();
    this.lightningBonusPopup = popup;
    this.popupBg = this.initPopupBg();
    this.fadeInAnimation = this.initFadeInAnimation();
    this.fadeOutAnimation = this.initFadeOutAnimation();
    this.addChild(this.popupBg);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.bonusGamePopup));
    popupBg.anchor.set(0.5);
    popupBg.x = 0;
    popupBg.y = 0;

    return popupBg;
  }

  public handleChangeBg(texture: string): void {
    this.popupBg.texture = Texture.from(texture);
  }

  private initFadeInAnimation(): Animation {
    return new Tween({
      object: this.popupBg,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 300,
    });
  }

  private initFadeOutAnimation(): Animation {
    return new Tween({
      object: this.popupBg,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: 300,
    });
  }

  public show(): void {
    this.lightningBonusPopup.visible = true;
    this.lightningBonusPopup.backgroundFadeInAnimation.start();
    this.lightningBonusPopup.fadeInAnimation.start();
    this.fadeInAnimation.start();
  }

  public hide(): void {
    this.fadeOutAnimation.start();
    this.lightningBonusPopup.backgroundFadeOutAnimation.start();
    this.lightningBonusPopup.fadeOutAnimation.start();
    this.lightningBonusPopup.fadeOutAnimation.addOnComplete(() => {
      eventManager.emit(EventTypes.START_LIGHTNING_BONUS_GAME);
      this.lightningBonusPopup.visible = false;
      setBonusGameDataRequested(true);
    });
  }

  public override resize(width: number, height: number): void {
    const landscape = height < width;
    if (isMobile.any) {
      this.scale.set(Math.min(1, landscape ? height / 1000 : width / 600));
    } else {
      this.scale.set(Math.min(1, landscape ? height / 1000 : width / 1000));
    }
  }
}

export default LightningBonusPopupBackgroundStatic;
