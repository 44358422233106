import { ITextStyle, Sprite, Texture } from 'pixi.js';

import { SpriteButton, SpriteButtonProps, SpriteButtonState } from './SpriteButton';

export interface FeatureButtonProps extends SpriteButtonProps {
  [SpriteButtonState.ACTIVE]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
  };
  isActive?: boolean;

  foreground: { texture: Texture };
}
export class FeatureButton extends SpriteButton {
  private isActive = false;

  constructor(props: FeatureButtonProps) {
    super(props);
    this.isActive = !!props.isActive;
    this.state = SpriteButtonState.DEFAULT;
    this.textures[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]
      ? props[SpriteButtonState.ACTIVE]!.texture
      : props[SpriteButtonState.DEFAULT].texture;
    if (this.textStyles) {
      this.textStyles[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]?.textStyle as Partial<ITextStyle>;
      this.textField?.setStyle(
        this.isActive ? this.textStyles[SpriteButtonState.ACTIVE]! : this.textStyles[SpriteButtonState.DEFAULT]!,
      );
    }
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
    this.initForeground(props.foreground);
  }

  initForeground({ texture }: { texture: Texture }): void {
    this.textField!.getText().position.y = 220;
    const foreground = new Sprite(texture);
    foreground.x = -200;
    foreground.y = -200;
    this.addChild(foreground);
  }

  public turnOn(): void {
    this.isActive = true;
    this.changeState(SpriteButtonState.ACTIVE);
  }

  public turnOff(): void {
    this.isActive = false;
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected override onMouseOverCallback(): void {
    if (!this.isActive) super.onMouseOverCallback();
  }

  protected override onClickCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.onClick) {
      this.onClick();
    }
  }

  protected override onMouseOutCallback(): void {
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
  }

  protected override onMouseDownCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.PRESSED);
  }
}
