import i18n from 'i18next';
import { Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import {
  setBrokenGame,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsShowSoundToast,
  setIsSoundLoading,
} from '../../../gql/cache';
import { Logic } from '../../../logic';
import { handleChangeRestriction } from '../../../utils';
import type Animation from '../../animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TextField } from '../../components/TextField';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';
import { popupTextStyle } from '../textStyles';

import SpecialShotBonusPopupBackgroundStatic from './specialShotBonusPopupBackgroundStatic';

export class SpecialShotBonusPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: SpecialShotBonusPopupBackgroundStatic;

  protected title!: TextField;

  protected pressToContinueText!: TextField;

  protected textContainer!: ViewContainer;

  public fadeOutAnimation: Animation;

  public fadeInAnimation: Animation;

  public backgroundFadeInAnimation: Animation;

  public backgroundFadeOutAnimation: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.zIndex = 3;
    this.initBackground();
    this.initTextContainer();
    this.initTitle();
    this.initPressToContinueText();
    this.fadeInAnimation = this.initFadeInAnimation();
    this.fadeOutAnimation = this.initFadeOutAnimation();
    this.backgroundFadeInAnimation = this.initBackGroundFadeInAnimation();
    this.backgroundFadeOutAnimation = this.initBackGroundFadeOutAnimation();
    this.init();
    eventManager.addListener(EventTypes.SPECIAL_THOR_START, this.handleClose);
  }

  private initTextContainer(): void {
    this.textContainer = new ViewContainer();
  }

  private initBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.popupBackground = new SpecialShotBonusPopupBackgroundStatic(this);
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    this.textContainer.addChild(this.title.getText(), this.pressToContinueText.getText());
    this.popupBackground.addChild(this.textContainer);
  }

  private initTitle(): void {
    this.title = new TextField(i18n.t('specialShotBonusPopupTitle'), 400, 200, popupTextStyle);
    this.title.text.anchor.set(0.5, 0.5);
    this.title.text.position.y = -250;
  }

  private initPressToContinueText(): void {
    this.pressToContinueText = new TextField(i18n.t('bonusGamePressToContinue'), 400, 200, popupTextStyle);
    this.pressToContinueText.text.anchor.set(0.5, 0.5);
    this.pressToContinueText.text.position.y = 250;
  }

  private initFadeInAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 300,
    });
  }

  private initFadeOutAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: 300,
    });
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public override show(_props?: PopupProps): void {
    if (!setBrokenGame()) {
      AudioApi.play({ type: ISongs.BGM_SHOT_Loop });
    }
    if (setIsDuringBigWinLoop()) {
      AudioApi.fadeOut(0, ISongs.BGM_SHOT_Loop);
    }
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    if (!AudioApi.isInitialized) {
      eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
    }
    AudioApi.changeRestriction(
      false,
      [],
      () => setIsSoundLoading(true),
      () => {
        setIsShowSoundToast(false);
        handleChangeRestriction(Logic.the.controller.gameMode);
      },
    );

    clearTimeout(this.autoSkipDelay);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    this.position.set(width / 2, height / 2);
  }
}
