import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../info.module.scss';

type Props = { texts: string[] };
export const InfoTextBlock: React.FC<Props> = ({ texts }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles['p']} ${styles['left']}`}>
      {texts.map((v) => (
        <div key={v} dangerouslySetInnerHTML={{ __html: t(v) }} className="game-rule" />
      ))}
    </div>
  );
};
