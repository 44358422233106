import { setSlotConfig } from '../gql/cache';
import type { Block, Icon } from '../slotMachine/d';

export const getCascadeSlots = ({ slotIds }: { slotIds: Block[] }): Icon[] => {
  const slotIcons: Icon[] = [];
  const { icons } = setSlotConfig();
  for (let i = 0; i < slotIds.length; i++) {
    slotIcons.push(icons.find((icon) => icon.id === slotIds[i as number]!.slotId)!);
  }
  return slotIcons;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
};
