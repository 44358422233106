import i18n from 'i18next';
import * as _ from 'lodash';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../../config';
import { EventTypes, FeatureState, IBonus } from '../../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../../gql/cache';
import type { IBetSettings } from '../../../gql/d';
import { getBetsSetting } from '../../../gql/fromFragment';
import { ResourceTypes } from '../../../resources.d';
import { SLOT_FEATURES_CONFIG } from '../../../slotFeaturesConfig/slotFeaturesConfig';
import { getBonusIdByFeature, normalizeCoins, showCurrency } from '../../../utils';
import {
  betValueStyle,
  bonusButtonActiveTextStyle,
  bonusButtonDefaultTextStyle,
  bonusButtonDisabledTextStyle,
  bonusButtonHoverTextStyle,
  bonusButtonPressedTextStyle,
  currentBetStyle,
  titleBaseTextStyle,
  totalCostBaseTextStyle,
} from '../../buyFeature/textStyles';
import { FeatureButton } from '../../components/FeatureButton';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { PopupTypes, eventManager } from '../../config';
import { PopupController } from '../PopupController';
import { Popup } from '../popup';

import { BUY_FEATURE_POPUP_CONFIG } from './buyFeaturePopup.config';
import BuyFeaturePopupBackgroundAnimated from './buyFeaturePopupBackgroundAnimated';
import BuyFeaturePopupBackgroundStatic from './buyFeaturePopupBackgroundStatic';

class BuyFeaturePopup extends Popup {
  private popupBg!: BuyFeaturePopupBackgroundStatic | BuyFeaturePopupBackgroundAnimated;

  private lightningBonusModeBtn: FeatureButton;

  private specialShotBonusModeBtn: FeatureButton;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: TextField;

  private amountText: TextField;

  private totalCostText: TextField;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private betAmountBackplate: Sprite;

  private totalBetBackplate: Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private betValue: TextField;

  private lightningBonusTotalCostValue: TextField;

  private specialShotBonusTotalCostValue: TextField;

  private isNoFunds: boolean;

  private balance: number;

  private featureState: FeatureState = FeatureState.LIGHTNING_BONUS;

  private labelText: TextField;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.coinMultiplier = setSlotConfig().lineSets[0]!.coinAmountMultiplier;
    this.balance = 0;
    this.zIndex = 3;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.initBackground();
    this.titleText = this.initTitle();
    this.amountText = this.initAmountText();
    this.totalCostText = this.initTotalCostText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betAmountBackplate = this.initBetAmountBackplate();
    this.totalBetBackplate = this.initTotalBetBackplate();
    this.betValue = this.initBetValue();
    this.lightningBonusTotalCostValue = this.initLightningBonusTotalCostValue();
    this.specialShotBonusTotalCostValue = this.initSpecialShotBonusTotalCostValue();
    this.lightningBonusModeBtn = this.initLightningBonusModeBtn();
    this.specialShotBonusModeBtn = this.initSpecialShotBonusModeBtn();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.labelText = this.initLabelText();
    this.init();
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      PopupController.the.closeCurrentPopup();
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  public override show(): void {
    super.show();
    this.closeAllAnimationsInSlot();
    this.visible = true;
  }

  public close(): void {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_POPUP);
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.betAmountBackplate);
    this.addChild(this.titleText.getText());
    this.addChild(this.amountText.getText());
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.betValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.lightningBonusModeBtn);
    this.addChild(this.specialShotBonusModeBtn);
    this.addChild(this.lightningBonusTotalCostValue.getText());
    this.addChild(this.specialShotBonusTotalCostValue.getText());
  }

  private initBackground(): void {
    this.popupBg = SLOT_FEATURES_CONFIG.REACT_APP_SLOT_IS_ANIMATED_POPUPS
      ? new BuyFeaturePopupBackgroundAnimated()
      : new BuyFeaturePopupBackgroundStatic();
  }

  private initTitle(): TextField {
    const title = new TextField(i18n.t<string>('buyFeatureTitle'), 750, 100, titleBaseTextStyle);
    const config = BUY_FEATURE_POPUP_CONFIG.title;
    title.text.y = config.top;
    title.text.x = config.x;
    title.text.anchor.set(0.5);
    title.text.name = 'BuyFeatureTitle';
    return title;
  }

  private initAmountText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyFeatureBetPerGame'),
      400,
      100,
      titleBaseTextStyle as Partial<ITextStyle>,
    );
    const config = BUY_FEATURE_POPUP_CONFIG.buyFeaturePerGame;
    amountText.text.anchor.set(0.5, 0);
    amountText.text.y = config.top;
    amountText.text.x = config.left;
    amountText.text.name = 'BuyFeatureBetPerGame';
    return amountText;
  }

  private initTotalCostText(): TextField {
    const amountText = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      400,
      100,
      totalCostBaseTextStyle as Partial<ITextStyle>,
    );
    amountText.text.y = 390;
    amountText.text.x = 650;
    amountText.text.anchor.set(0.5, 0);

    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    const config = BUY_FEATURE_POPUP_CONFIG.plusMinusBtn;
    minusBtn.y = config.top + minusBtn.height / 2;
    minusBtn.x = config.minusBtn.left + minusBtn.width / 2;

    return minusBtn;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    const config = BUY_FEATURE_POPUP_CONFIG.cancelOkBtn;
    cancelBtn.y = config.top + cancelBtn.height / 2;
    cancelBtn.x = config.cancelBtn.left + cancelBtn.width / 2;
    cancelBtn.anchor.set(0.5, 0.5);
    return cancelBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.y = BUY_FEATURE_POPUP_CONFIG.plusMinusBtn.top + plusBtn.height / 2;
    plusBtn.x = BUY_FEATURE_POPUP_CONFIG.plusMinusBtn.plusBtn.left + plusBtn.width / 2;

    return plusBtn;
  }

  private initBetAmountBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.anchor.set(0.5, 0.5);
    input.x = BUY_FEATURE_POPUP_CONFIG.totalBetBackplate.left + input.width / 2;
    input.y = BUY_FEATURE_POPUP_CONFIG.totalBetBackplate.top + input.height / 2;

    return input;
  }

  private initTotalBetBackplate(): Sprite {
    const input = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    input.anchor.set(0.5, 0.5);
    input.x = BUY_FEATURE_POPUP_CONFIG.totalBetBackplate.left + input.width / 2;
    input.y = BUY_FEATURE_POPUP_CONFIG.totalBetBackplate.top + input.height / 2;
    return input;
  }

  private initBetValue(): TextField {
    const betValue = new TextField(
      `${formatNumber({
        currency: this.currency,
        value: normalizeCoins(this.getBetValue()),
        showCurrency: showCurrency(this.currency),
      })}`,
      300,
      100,
      currentBetStyle as Partial<ITextStyle>,
    );
    betValue.text.y = BUY_FEATURE_POPUP_CONFIG.betValue.y;
    betValue.text.x = BUY_FEATURE_POPUP_CONFIG.betValue.left;
    betValue.text.anchor.set(0.5, 0);
    betValue.text.name = 'BetValue';
    return betValue;
  }

  private initLightningBonusTotalCostValue(): TextField {
    const betValue = new TextField(
      this.getTotalCost(FeatureState.LIGHTNING_BONUS),
      300,
      100,
      betValueStyle as Partial<ITextStyle>,
    );
    const config = BUY_FEATURE_POPUP_CONFIG.bonusTotalCost;
    betValue.text.name = 'LightningBonusTotalCostValue';
    betValue.text.y = config.top;
    betValue.text.x = config.lightning.x;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private initSpecialShotBonusTotalCostValue(): TextField {
    const betValue = new TextField(
      this.getTotalCost(FeatureState.SPECIAL_SHOT_BONUS),
      300,
      100,
      bonusButtonDefaultTextStyle as Partial<ITextStyle>,
    );
    const config = BUY_FEATURE_POPUP_CONFIG.bonusTotalCost;
    betValue.text.name = 'SpecialShotBonusTotalCostValue';
    betValue.text.y = config.top;
    betValue.text.x = config.specialShot.x;
    betValue.text.anchor.set(0.5, 0);

    return betValue;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    PopupController.the.closeCurrentPopup();
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
  }

  private initLightningBonusModeBtn(): FeatureButton {
    const baseBtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.featureBtnActive),
        textStyle: bonusButtonActiveTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.featureBtnDefault),
        textStyle: bonusButtonDefaultTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.featureBtnHover),
        textStyle: bonusButtonHoverTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.featureBtnPressed),
        textStyle: bonusButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.featureBtnDisabled),
        textStyle: bonusButtonDisabledTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.handleLightningBonusBtnClick(),
      onTouchStart: () => this.handleLightningBonusBtnClick(),
      isActive: true,
      textFiled: this.initLightningBonusButtonText(),
      foreground: { texture: Texture.from(ResourceTypes.lightningBonusIcon) },
    });
    baseBtn.y = 440;
    baseBtn.x = 142 + baseBtn.width / 2;
    return baseBtn;
  }

  private initSpecialShotBonusModeBtn(): FeatureButton {
    const specialShotbtn = new FeatureButton({
      [SpriteButtonState.ACTIVE]: {
        texture: Texture.from(ResourceTypes.featureBtnActive),
        textStyle: bonusButtonActiveTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.featureBtnDefault),
        textStyle: bonusButtonDefaultTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.featureBtnHover),
        textStyle: bonusButtonHoverTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.featureBtnPressed),
        textStyle: bonusButtonPressedTextStyle as Partial<ITextStyle>,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.featureBtnDisabled),
        textStyle: bonusButtonDisabledTextStyle as Partial<ITextStyle>,
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.handleSpecialShotBonusBtnClick(),
      onTouchStart: () => this.handleSpecialShotBonusBtnClick(),
      textFiled: this.initSpecialShotModeButtonText(),
      foreground: { texture: Texture.from(ResourceTypes.specialShotBonusIcon) },
    });
    specialShotbtn.y = 440;
    specialShotbtn.x = 586 + specialShotbtn.width / 2;
    return specialShotbtn;
  }

  private handleLightningBonusBtnClick(): void {
    this.changeFeatureState(FeatureState.LIGHTNING_BONUS);
    this.specialShotBonusModeBtn.turnOff();
    this.lightningBonusModeBtn.turnOn();
    this.updateBets();
    this.lightningBonusTotalCostValue.setStyle(betValueStyle as Partial<ITextStyle>);
    this.specialShotBonusTotalCostValue.setStyle(bonusButtonDefaultTextStyle);
  }

  private handleSpecialShotBonusBtnClick(): void {
    this.changeFeatureState(FeatureState.SPECIAL_SHOT_BONUS);
    this.lightningBonusModeBtn.turnOff();
    this.specialShotBonusModeBtn.turnOn();
    this.updateBets();
    this.specialShotBonusTotalCostValue.setStyle(betValueStyle as Partial<ITextStyle>);
    this.lightningBonusTotalCostValue.setStyle(bonusButtonDefaultTextStyle);
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
    });
    okBtn.anchor.set(0.5, 0.5);
    okBtn.y = BUY_FEATURE_POPUP_CONFIG.cancelOkBtn.top + okBtn.height / 2;
    okBtn.x = BUY_FEATURE_POPUP_CONFIG.cancelOkBtn.okBtn.left + okBtn.width / 2;

    return okBtn;
  }

  private initLabelText(): TextField {
    const label = new TextField(i18n.t<string>('buyFeatureButtonBuy'), 500, 100, betValueStyle as Partial<ITextStyle>);
    label.text.anchor.set(0.5, 0.5);
    label.text.x = 650;
    label.text.y = 800;
    return label;
  }

  private initLightningBonusButtonText(): TextField {
    const textField = new TextField(
      i18n.t<string>('lightningBonusButtonText'),
      300,
      150,
      bonusButtonActiveTextStyle as Partial<ITextStyle>,
    );
    textField.text.anchor.set(0.5, 0.5);
    return textField;
  }

  private initSpecialShotModeButtonText(): TextField {
    const textField = new TextField(
      i18n.t<string>('specialShotBonusButtonText'),
      300,
      150,
      bonusButtonActiveTextStyle as Partial<ITextStyle>,
    );
    textField.text.anchor.set(0.5, 0.5);
    return textField;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    const isMinBet = this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet;
    if (isMinBet) {
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    const isMaxBet = this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet;
    if (isMaxBet) {
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    AudioApi.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }

  private updateBets(): void {
    const bet = `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue()),
      showCurrency: showCurrency(this.currency),
    })}`;
    this.betValue.text.text = bet;
    this.lightningBonusTotalCostValue.text.text = this.getTotalCost(FeatureState.LIGHTNING_BONUS);
    this.specialShotBonusTotalCostValue.text.text = this.getTotalCost(FeatureState.SPECIAL_SHOT_BONUS);
  }

  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber({
      currency: this.currency,
      value: normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency: showCurrency(this.currency),
    })}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    if (!this.isNoFunds) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE_CONFIRMATION, {
        totalCost: this.getTotalCost(this.featureState),
        coinAmount: this.betSettings.bets[this.betAmount] as number,
        featureState: this.featureState,
      });
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    const isMinBet = bet === this.betSettings!.minBet;
    const isMaxBet = bet === this.betSettings!.maxBet;

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(this.featureState));

    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }

    if (isMinBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (isMaxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  };

  private closeAllAnimationsInSlot(): void {
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }
}

export default BuyFeaturePopup;
