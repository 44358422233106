import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS_WIN_ANIMATIONS, SlotId } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setIsTurboSpin } from '../../../gql/cache';
import type Animation from '../../animations/animation';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import { SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_WIDTH, eventManager } from '../../config';
import type { Icon } from '../../d';

import type { WinSlotsContainer } from './winSlotsContainer';

export class CascadeWinSlotsPresentation extends ViewContainer implements WinSlotsContainer {
  public animation: Animation | null = null;

  public screenWidth = 0;

  public screenHeight = 0;

  constructor() {
    super();
    this.zIndex = 10;
    eventManager.addListener(EventTypes.START_CASCADE_WIN_ANIMATION, this.showWin.bind(this));
  }

  public createSlotSpineAnimation(_id: number, slotId: SlotId, _srcName: string, _animationName: string): Animation {
    const timeScale = setIsTurboSpin() ? 1.5 : 1;
    const dummy = Tween.createDelayAnimation(1200 / timeScale);
    const breakSpine = new Spine(Loader.shared.resources['blocks']!.spineData!);
    let breakAnimation = 'wood_block_destruction';
    let breakSound = ISongs.BROKEN1;

    switch (slotId) {
      case SlotId.D2:
        breakAnimation = 'stone_block_destruction';
        break;

      case SlotId.D3:
        breakAnimation = 'iron_block_destruction';
        break;

      case SlotId.D4:
        breakAnimation = 'silver_block_destruction';
        breakSound = ISongs.BROKEN2;
        break;

      case SlotId.D5:
        breakAnimation = 'gold_block_destruction';
        breakSound = ISongs.BROKEN2;
        break;

      default:
        break;
    }

    dummy.addOnStart(() => {
      const y = (SLOTS_PER_REEL_AMOUNT - 0.5) * SLOT_HEIGHT;
      const x = (SLOT_WIDTH / 2) * 1.05;

      breakSpine.position.set(x, y);
      this.addChild(breakSpine);
      breakSpine.state.addListener({
        complete: (_entry) => {
          breakSpine.visible = false;
          setTimeout(() => {
            breakSpine.destroy();
          }, 0);
        },
      });
      breakSpine.state.setAnimation(0, breakAnimation, true);
      AudioApi.play({ type: breakSound, stopPrev: true });
    });
    return dummy;
  }

  public showWin(spinResult: Icon[]): void {
    const currentSpinResult = [...spinResult];
    this.animation = this.createCascadeAnimation(currentSpinResult);
    this.animation.start();
  }

  public createCascadeAnimation(spinResult: Icon[]): Animation {
    const chain = new AnimationChain();
    const animationGroup = new AnimationGroup();
    const spinResultLength = spinResult.length;
    animationGroup.addAnimation(
      this.createSlotSpineAnimation(
        spinResultLength - 1,
        spinResult[(spinResultLength - 1) as number]!.id,
        MAPPED_SYMBOLS_WIN_ANIMATIONS[spinResult[(spinResultLength - 1) as number]!.id].src!,
        MAPPED_SYMBOLS_WIN_ANIMATIONS[spinResult[(spinResultLength - 1) as number]!.id].animation!,
      ),
    );
    const timeScale = setIsTurboSpin() ? 1.5 : 1;
    const audioDelay = Tween.createDelayAnimation(1000 / timeScale);
    // audioDelay.addOnComplete(() =>
    //   // ISongs.BLOCK_DESTROY
    //   console.log('ISongs.BLOCK_DESTROY'),
    // );
    animationGroup.addAnimation(audioDelay);
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY);
      eventManager.emit(EventTypes.NEXT_CASCADE);
    });
    chain.appendAnimation(animationGroup);
    return chain;
  }

  protected override resize(width: number, height: number): void {
    this.screenHeight = height;
    this.screenWidth = width;
  }
}
