import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { EventTypes, LightningEntryAnimation } from '../../global.d';
import { eventManager } from '../config';
import { LightningThorMovements } from '../d';

class LightningThor {
  protected lightningThor: Spine;

  public screenHeight = 0;

  public screenWidth = 0;

  constructor() {
    this.lightningThor = this.initLightningThor();
    this.initLightningThorListeners();
    this.setLightningThorAnimations(LightningThorMovements.idle, true);
  }

  get getLightningThor(): Spine {
    return this.lightningThor;
  }

  private initLightningThor(): Spine {
    const lightningThor = new Spine(Loader.shared.resources['lightningThor']!.spineData!);
    lightningThor.name = 'LightningThor';
    lightningThor.zIndex = 10;
    return lightningThor;
  }

  private onResize(width: number, height: number): void {
    this.screenHeight = height;
    this.screenWidth = width;
  }

  private initLightningThorListeners(): void {
    this.lightningThor.state.addListener({
      complete: (entry: LightningEntryAnimation) => {
        switch (entry.animation.name) {
          case LightningThorMovements.in:
            this.setLightningThorAnimations(LightningThorMovements.idle_2, true);
            break;
          default:
            break;
        }
      },
    });

    eventManager.addListener(EventTypes.LIGHTNING_THOR_IN, () => {
      this.setLightningThorAnimations(LightningThorMovements.in, false);
    });

    eventManager.addListener(EventTypes.RESIZE, (width, height) => this.onResize(width, height));
  }

  public setLightningThorAnimations(animation: LightningThorMovements, loop: boolean): void {
    this.lightningThor.state.setAnimation(0, animation, loop);
  }
}

export default LightningThor;
