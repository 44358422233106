import { Spine } from 'pixi-spine';
import { DisplayObject, Loader, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { BonusGameResult, EventTypes, GameMode, ISettledBet, LightningEntryAnimation } from '../../global.d';
import { setCurrentBonus } from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { generateMultiplierPositions } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import { createLightningMultiplierAppear } from '../animations/helper';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { MAX_WIN, eventManager } from '../config';
import { LightningThorMovements } from '../d';

import LightningBonusButton from './lightningBonusButton';
import LightningBonusMultiplier from './lightningBonusMultiplier';
import LightningThor from './lightningThor';

interface Coordinate {
  x: number;
  y: number;
}

interface IPreviousCoordinates {
  portrait: Coordinate[];
  landscape: Coordinate[];
}

interface IFinalCoordinates {
  portrait: Coordinate;
  landscape: Coordinate;
}

class LightningBonus extends ViewContainer {
  public lightningThor: LightningThor;

  private spineAnimation: Spine;

  public lightningPushBtn!: ViewContainer;

  private lightningBonusStarted = false;

  public screenHeight = 0;

  public screenWidth = 0;

  public multipliersList: LightningBonusMultiplier[] = [];

  public betResult: BonusGameResult[] = [];

  public tmpBetResult: BonusGameResult[] = [];

  public animation = new AnimationChain();

  public sumMultiply!: LightningBonusMultiplier;

  public multipliersAnimationList: AnimationGroup[] = [];

  public multipliersFinalAnimation: AnimationGroup[] = [];

  public prevMultiplier = 1;

  public sumMultiplyValue = 1;

  public previousCoordinates!: IPreviousCoordinates;

  public finalCoordinate!: IFinalCoordinates;

  private deviceOrientation: 'portrait' | 'landscape' = 'portrait';

  private firstContinuation = true;

  constructor() {
    super();
    this.zIndex = 4;
    this.addClickListener();
    this.spineAnimation = this.initSpineAnimation();
    this.addChild(this.spineAnimation);
    this.lightningThor = this.initLightningThor();
    this.addChild(this.lightningThor.getLightningThor);
    this.spineAnimation.state.addListener({
      complete: (entry: LightningEntryAnimation) => {
        switch (entry.animation.name) {
          case 'multiplier_collect':
            AudioApi.play({
              type: ISongs.MULTIPLIER_RESULT_DISPLAY,
              stopPrev: true,
            });
            this.sumMultiply.getLightningBonusMultiplier.visible = true;
            this.sumMultiply.setMultiplierScale();
            setTimeout(() => Logic.the.changeState(States.BEFORE_WIN), 1000);
            this.removeButton();
            break;
          case 'multiplier_appear':
            this.multipliersList[0]!.getLightningBonusMultiplier.visible = true;
            this.multipliersList[0]!.setMultiplierScale();
            break;
          default:
            break;
        }
      },
    });
    eventManager.addListener(EventTypes.LIGHTNING_PUSH_CLICKED, () => this.displayMultiplier());

    eventManager.addListener(EventTypes.HIDE_LIGHTNING_FINAL_MULTIPLIER, () =>
      this.removeLightningBonusFinalMultiplier(),
    );

    eventManager.addListener(EventTypes.CHANGE_BONUS_GAME_ZINDEX, (zIndex: number) => {
      this.zIndex = zIndex;
    });

    eventManager.addListener(EventTypes.LIGHTNING_BONUS_DATA, (betResult: ISettledBet) => {
      this.betResult = betResult.bet.data.features.lightningLotteryResult;
      this.tmpBetResult = [...this.betResult];
      this.initLightningBonusMultipliers();
    });
  }

  private initSpineAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['lightningBonusMultipliers']!.spineData!);
    animation.x = this.screenWidth / 2;
    animation.y = this.screenHeight / 2;
    animation.name = 'SpineAnimation';
    animation.zIndex = 20;
    animation.scale.set(isMobile.any ? 1 : 1.5);
    return animation;
  }

  private initLightningBonusMultipliers(): void {
    const distance = isMobile.any ? 100 : 200;
    const parentCoordinates = {
      portrait: { x: 0, y: 0 },
      landscape: { x: 0, y: 0 },
    };
    let prevMultiplier = 1;
    let mainMultiplier = 0;
    for (let i = 0; i < setCurrentBonus().rounds; i++) {
      const multiplyResult = this.betResult.shift();
      const multiplyValue = multiplyResult?.value.split('_')[1];
      this.sumMultiplyValue *= Number(multiplyValue);
      if (i === 0) {
        const halfWidth = window.innerWidth / 2;
        const halfHeight = window.innerHeight / 2;
        let initialXCoordinatePortrait = 0;
        let initialYCoordinatePortrait = 0;
        let initialXCoordinateLandscape = 0;
        let initialYCoordinateLandscape = 0;
        if (this.deviceOrientation === 'portrait') {
          initialXCoordinatePortrait = halfWidth;
          initialYCoordinatePortrait = halfHeight;
          initialXCoordinateLandscape = halfHeight;
          initialYCoordinateLandscape = halfWidth;
        } else {
          initialXCoordinatePortrait = halfHeight;
          initialYCoordinatePortrait = halfWidth;
          initialXCoordinateLandscape = halfWidth;
          initialYCoordinateLandscape = halfHeight;
        }

        this.previousCoordinates.portrait = [{ x: initialXCoordinatePortrait, y: initialYCoordinatePortrait }];
        this.previousCoordinates.landscape = [{ x: initialXCoordinateLandscape, y: initialYCoordinateLandscape }];
        const coordinateData = {
          portrait: {
            initialCoordinate: {
              x: initialXCoordinatePortrait,
              y: initialYCoordinatePortrait,
            },
            targetCoordinate: {
              x: initialXCoordinatePortrait,
              y: initialYCoordinatePortrait,
            },
          },
          landscape: {
            initialCoordinate: {
              x: initialXCoordinateLandscape,
              y: initialYCoordinateLandscape,
            },
            targetCoordinate: {
              x: initialXCoordinateLandscape,
              y: initialYCoordinateLandscape,
            },
          },
        };
        parentCoordinates.portrait = coordinateData.portrait.initialCoordinate;
        parentCoordinates.landscape = coordinateData.landscape.initialCoordinate;
        this.finalCoordinate = parentCoordinates;
        const multiplier = new LightningBonusMultiplier(
          Number(multiplyValue),
          '1',
          true,
          false,
          this.screenWidth,
          this.screenHeight,
          coordinateData,
        );
        mainMultiplier = Number(multiplyValue);
        multiplier.getLightningBonusMultiplier.visible = false;
        this.multipliersList.push(multiplier);
        this.addChild(multiplier.getLightningBonusMultiplier);
      } else if (i === 1) {
        let newMultiply = Number(multiplyValue);
        prevMultiplier = newMultiply;
        newMultiply -= 1;
        const newPortraitCoordinates: Coordinate[] = generateMultiplierPositions(
          parentCoordinates.portrait.x,
          parentCoordinates.portrait.y,
          newMultiply,
          distance,
          isMobile.any ? 50 : 80,
          this.deviceOrientation === 'portrait' ? window.innerWidth : window.innerHeight,
          this.deviceOrientation === 'portrait' ? window.innerHeight : window.innerWidth,
        );
        const newLandscapeCoordinates: Coordinate[] = generateMultiplierPositions(
          parentCoordinates.landscape.x,
          parentCoordinates.landscape.y,
          newMultiply,
          distance,
          isMobile.any ? 50 : 80,
          this.deviceOrientation === 'portrait' ? window.innerHeight : window.innerWidth,
          this.deviceOrientation === 'portrait' ? window.innerWidth : window.innerHeight,
        );
        for (let k = 0; k < newPortraitCoordinates.length; k++) {
          const coordinateData = {
            portrait: {
              initialCoordinate: {
                x: parentCoordinates.portrait.x,
                y: parentCoordinates.portrait.y,
              },
              targetCoordinate: {
                x: (newPortraitCoordinates[k as number] as Coordinate).x,
                y: (newPortraitCoordinates[k as number] as Coordinate).y,
              },
            },
            landscape: {
              initialCoordinate: {
                x: parentCoordinates.landscape.x,
                y: parentCoordinates.landscape.y,
              },
              targetCoordinate: {
                x: (newLandscapeCoordinates[k as number] as Coordinate).x,
                y: (newLandscapeCoordinates[k as number] as Coordinate).y,
              },
            },
          };
          const multiplier = new LightningBonusMultiplier(
            mainMultiplier,
            '2',
            false,
            false,
            this.screenWidth,
            this.screenHeight,
            coordinateData,
          );
          multiplier.getLightningBonusMultiplier.visible = false;
          this.multipliersList.push(multiplier);
          this.addChild(multiplier.getLightningBonusMultiplier);
        }
        this.previousCoordinates.portrait = [...this.previousCoordinates.portrait, ...newPortraitCoordinates];
        this.previousCoordinates.landscape = [...this.previousCoordinates.landscape, ...newLandscapeCoordinates];
      } else {
        let newMultiplier = prevMultiplier * Number(multiplyValue);
        prevMultiplier = newMultiplier;
        newMultiplier -= this.multipliersList.length;
        const multipliersForTheLastCoordinate = newMultiplier % this.previousCoordinates[this.deviceOrientation].length;
        const equalNumberOfMultipliers = newMultiplier / this.previousCoordinates[this.deviceOrientation].length;
        const newCoordinates = {
          portrait: [] as Coordinate[],
          landscape: [] as Coordinate[],
        };
        for (let z = 0; z < this.previousCoordinates[this.deviceOrientation].length; z++) {
          let totalMultipliersPerCoordinate = equalNumberOfMultipliers;
          if (z === this.previousCoordinates[this.deviceOrientation].length - 1 && multipliersForTheLastCoordinate) {
            totalMultipliersPerCoordinate = multipliersForTheLastCoordinate;
          }
          const multiplierPortraitCoordinate = generateMultiplierPositions(
            this.previousCoordinates.portrait[z as number]!.x,
            this.previousCoordinates.portrait[z as number]!.y,
            totalMultipliersPerCoordinate,
            distance,
            isMobile.any ? 50 : 80,
            this.deviceOrientation === 'portrait' ? window.innerWidth : window.innerHeight,
            this.deviceOrientation === 'portrait' ? window.innerHeight : window.innerWidth,
          );

          const multiplierLandscapeCoordinate = generateMultiplierPositions(
            this.previousCoordinates.landscape[z as number]!.x,
            this.previousCoordinates.landscape[z as number]!.y,
            totalMultipliersPerCoordinate,
            distance,
            isMobile.any ? 50 : 80,
            this.deviceOrientation === 'portrait' ? window.innerHeight : window.innerWidth,
            this.deviceOrientation === 'portrait' ? window.innerWidth : window.innerHeight,
          );
          newCoordinates.portrait.push(...multiplierPortraitCoordinate);
          newCoordinates.landscape.push(...multiplierLandscapeCoordinate);
          for (let x = 0; x < totalMultipliersPerCoordinate; x++) {
            const coordinateData = {
              portrait: {
                initialCoordinate: {
                  x: this.previousCoordinates.portrait[z as number]!.x,
                  y: this.previousCoordinates.portrait[z as number]!.y,
                },
                targetCoordinate: {
                  x: multiplierPortraitCoordinate[x as number]!.x,
                  y: multiplierPortraitCoordinate[x as number]!.y,
                },
              },
              landscape: {
                initialCoordinate: {
                  x: this.previousCoordinates.landscape[z as number]!.x,
                  y: this.previousCoordinates.landscape[z as number]!.y,
                },
                targetCoordinate: {
                  x: multiplierLandscapeCoordinate[x as number]!.x,
                  y: multiplierLandscapeCoordinate[x as number]!.y,
                },
              },
            };
            const multiplier = new LightningBonusMultiplier(
              mainMultiplier,
              `${i + 1}`,
              false,
              false,
              this.screenWidth,
              this.screenHeight,
              coordinateData,
            );
            multiplier.getLightningBonusMultiplier.visible = false;
            this.multipliersList.push(multiplier);
            this.addChild(multiplier.getLightningBonusMultiplier);
          }
        }
        this.previousCoordinates.portrait = [...this.previousCoordinates.portrait, ...newCoordinates.portrait];
        this.previousCoordinates.landscape = [...this.previousCoordinates.landscape, ...newCoordinates.landscape];
      }
    }
    const animation = new Tween({
      object: this.lightningThor.getLightningThor,
      property: TweenProperties.Y,
      propertyBeginValue: this.screenHeight / 2 + this.lightningThor.getLightningThor.height / 10,
      target: this.screenHeight / 2,
      duration: 300,
    });
    animation.start();
    animation.addOnComplete(() => {
      const multiply = this.getCurrentMultiplyValue();
      this.initLightningBonusButton(false, multiply, true);
    });
  }

  private initSumMultiply(): void {
    this.spineAnimation.state.setAnimation(0, 'multiplier_collect', false);
    this.sumMultiply = new LightningBonusMultiplier(
      Math.min(this.sumMultiplyValue, MAX_WIN),
      'sum',
      false,
      true,
      this.screenWidth,
      this.screenHeight,
    );
    this.sumMultiply.getLightningBonusMultiplier.x = this.finalCoordinate[this.deviceOrientation].x;
    this.sumMultiply.getLightningBonusMultiplier.y = this.finalCoordinate[this.deviceOrientation].y;
    this.sumMultiply.getLightningBonusMultiplier.visible = false;
    this.addChild(this.sumMultiply.getLightningBonusMultiplier);
  }

  private addClickListener(): void {
    this.interactive = true;
    this.on('click', () => this.onClick());
    this.on('touchstart', () => this.onClick());
  }

  private removeClickListener(): void {
    this.interactive = false;
    this.removeAllListeners();
  }

  private onClick(): void {
    if (this.lightningBonusStarted) return;
    this.lightningBonusStarted = true;
    this.lightningThor.getLightningThor.state.setAnimation(0, LightningThorMovements.idle_2, true);
    eventManager.emit(EventTypes.LIGHTNING_THOR_START);
    this.removeClickListener();
  }

  private displayMultiplier(): void {
    const animationGroup = new AnimationGroup();
    this.animation.appendAnimation(animationGroup);
    if (this.tmpBetResult.length) {
      this.removeButton();
      const multiplyResult = this.tmpBetResult.shift();
      if (multiplyResult?.type === 'Failed') {
        AudioApi.play({
          type: ISongs.MULTIPLIER_GATGERING,
          stopPrev: true,
        });
        this.startFinalAnimation();
      } else {
        setTimeout(() => {
          const multiply = this.getCurrentMultiplyValue();
          this.initLightningBonusButton(this.firstContinuation, multiply, false);
          if (this.firstContinuation) {
            this.firstContinuation = false;
          }
        }, 1000);
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: setCurrentBonus().currentRound + 1,
        });
        this.multipliersAnimationList = [];
        const foundMultipliers = this.multipliersList.filter(
          (e) => e.name === setCurrentBonus().currentRound.toString(),
        );
        if (setCurrentBonus().currentRound === 1) {
          AudioApi.play({ type: ISongs.INIT_DISP, stopPrev: true });
          this.spineAnimation.state.setAnimation(0, 'multiplier_appear', false);
        } else {
          for (let i = 0; i < foundMultipliers.length; i++) {
            const multiplier = foundMultipliers[i as number] as LightningBonusMultiplier;
            multiplier.getLightningBonusMultiplier.visible = true;
            multiplier.setMultiplierScale();
            const multiplierCoordinates = multiplier.coordinatesData;

            const appear = createLightningMultiplierAppear(
              multiplier.getLightningBonusMultiplier,
              multiplierCoordinates[this.deviceOrientation].targetCoordinate.x,
              multiplierCoordinates[this.deviceOrientation].targetCoordinate.y,
              multiplierCoordinates[this.deviceOrientation].initialCoordinate.x,
              multiplierCoordinates[this.deviceOrientation].initialCoordinate.y,
              1000,
              false,
            );
            this.multipliersAnimationList.push(appear);
          }
          AudioApi.play({ type: ISongs.MULTIPLIER_SPLITS, stopPrev: true });
          this.multipliersAnimationList.forEach((animation, _index) => {
            animation.start();
          });
        }
      }
    }
  }

  private startFinalAnimation(): void {
    this.multipliersList.forEach((multiplier, _index) => {
      const final = createLightningMultiplierAppear(
        multiplier.getLightningBonusMultiplier,
        this.finalCoordinate[this.deviceOrientation].x,
        this.finalCoordinate[this.deviceOrientation].y,
        multiplier.coordinatesData[this.deviceOrientation].targetCoordinate.x,
        multiplier.coordinatesData[this.deviceOrientation].targetCoordinate.y,
        500,
        false,
      );
      this.multipliersFinalAnimation.push(final);
    });

    this.multipliersFinalAnimation.forEach((animation, index) => {
      animation.start();
      animation.addOnComplete(() => {
        (this.multipliersList[index as number] as LightningBonusMultiplier).getLightningBonusMultiplier.visible = false;
        if (index === this.multipliersFinalAnimation.length - 1) {
          this.removeMultipliers();
          this.initSumMultiply();
        }
      });
    });
  }

  private setLightningThorPosition(): void {
    const isLandscapeMode = this.screenHeight < this.screenWidth;
    if (isMobile.any) {
      this.lightningThor.getLightningThor.scale.set(
        Math.min(0.8, isLandscapeMode ? this.screenHeight / 1200 : this.screenWidth / 900),
      );
    } else {
      const scale = isLandscapeMode ? this.screenHeight / 1300 : this.screenWidth / 1300;
      this.lightningThor.getLightningThor.scale.set(Math.min(0.6, scale));
    }
    const y = this.screenHeight / 2;
    this.lightningThor.getLightningThor.position.set(
      this.screenWidth / 2,
      this.lightningBonusStarted ? y : y + this.lightningThor.getLightningThor.height / 10,
    );
    this.spineAnimation.x = this.screenWidth / 2;
    this.spineAnimation.y = this.screenHeight / 2;
  }

  private initLightningBonusButton(isFirst: boolean, multiplier: number, initial: boolean): void {
    const lightningBonusBtn = new LightningBonusButton(
      this.screenWidth,
      this.screenHeight,
      isFirst,
      multiplier,
      initial,
    );
    this.addChild(lightningBonusBtn);
    eventManager.emit(EventTypes.SHOW_LIGHTNING_PUSH_ANIMATION);
  }

  private initLightningThor(): LightningThor {
    const lightningThor = new LightningThor();
    lightningThor.getLightningThor.scale.set(isMobile.any ? 0.6 : 0.8);
    lightningThor.getLightningThor.visible = false;
    return lightningThor;
  }

  protected override resize(width: number, height: number): void {
    this.screenHeight = height;
    this.screenWidth = width;
    this.deviceOrientation = width > height ? 'landscape' : 'portrait';
    this.setLightningThorPosition();
  }

  private cleanUp(): void {
    this.multipliersAnimationList = [];
    this.multipliersFinalAnimation = [];
    this.multipliersList = [];
    this.previousCoordinates = {
      portrait: [],
      landscape: [],
    };
    this.sumMultiplyValue = 1;
    this.prevMultiplier = 1;
    this.lightningThor.getLightningThor.y = this.screenHeight / 2 + this.lightningThor.getLightningThor.height / 10;
    this.firstContinuation = true;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.sumMultiplyValue = 1;
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.lightningBonusStarted = false;
        this.lightningThor.getLightningThor.visible = false;
        this.removeClickListener();
        this.removeButton();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.lightningThor.getLightningThor.visible = true;
        this.zIndex = 4;
        this.addClickListener();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.lightningBonusStarted = false;
        this.lightningThor.getLightningThor.visible = false;
        this.removeClickListener();
        this.removeButton();
        break;
      default:
        this.lightningBonusStarted = false;
        this.lightningThor.getLightningThor.visible = false;
        this.removeClickListener();
        this.removeButton();
    }
    this.cleanUp();
  }

  private removeButton(): void {
    this.children.forEach((child) => {
      if (child.name === 'LightningBonusButton') {
        this.removeChild(child);
      }
    });
  }

  private removeMultipliers(): void {
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i as number] as DisplayObject;
      if (child.name !== 'LightningThor' && child.name !== 'SpineAnimation') {
        this.removeChild(child);
        i -= 1;
      }
    }
  }

  private removeLightningBonusFinalMultiplier(): void {
    this.removeChild(this.sumMultiply.getLightningBonusMultiplier);
  }

  private getCurrentMultiplyValue = (): number => {
    const multiply = this.tmpBetResult[0] as BonusGameResult;
    return +multiply?.value.split('_')[1]!;
  };
}
export default LightningBonus;
