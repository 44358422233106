import { Spine } from 'pixi-spine';
import { Loader, isMobile } from 'pixi.js';

import { GameMode, UserBonus } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';

import { ForegroundConfig } from './foreground.config';

export class Foreground extends ViewContainer {
  override name = 'Foreground';

  private isLandscape = true;

  private spine!: Spine;

  constructor() {
    super();
    this.init();
  }

  protected override onBrokenGame(bonus: UserBonus): void {
    switch (bonus.gameMode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.handleLightningBonusChanges();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.handleSpecialShotBonusChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.handleLightningBonusChanges();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.handleSpecialShotBonusChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  private init() {
    const conf = ForegroundConfig[GameMode.BASE_GAME] as { name: string; animationName: string };
    this.spine = new Spine(Loader.shared.resources['gameAmbient']!.spineData!);
    this.spine.name = conf.name;
    this.spine.state.setAnimation(0, conf.animationName, true);

    this.addChild(this.spine);
  }

  private handleBaseModeChanges(): void {
    this.spine.visible = true;
  }

  private handleLightningBonusChanges(): void {
    this.spine.visible = false;
  }

  private handleSpecialShotBonusChanges(): void {
    this.spine.visible = false;
  }

  protected override resize(width: number, height: number): void {
    this.isLandscape = width >= height;
    if (this.spine.state.tracks[0]) {
      const { name } = (this.spine.state.tracks[0] as unknown as { animation: { name: string } }).animation;
      if (this.isLandscape && name.includes('mobile')) {
        this.spine.state.setAnimation(0, name.replace('mobile_', ''), true);
      }
      if (!this.isLandscape && !name.includes('mobile')) {
        this.spine.state.setAnimation(0, `${name}`, true);
      }
    }
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.spine.width / this.spine.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.spine.y = isMobile.any && !this.isLandscape ? -62 : 0;
      this.scale.set(height / this.spine.height);
    } else {
      this.spine.y = isMobile.any && !this.isLandscape ? -62 : 0;
      this.scale.set(width / this.spine.width);
    }
  }
}
