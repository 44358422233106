import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, type IAuthInput } from '../../global.d';
import { setIsTimeoutErrorMessage, setReplayBet, setSkipIntroScreen, setStressful } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { getSlotLoadProgressInfoGql, slotConfigGql } from '../../gql/query';
import { States } from '../../logic/config';
import { eventManager } from '../../slotMachine/config';
import { parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen/gameScreen';
import LoadScreen from '../LoadScreen/loadScreen';

const App: React.FC = () => {
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const { data: slotLoadProgressInfo } = useQuery<{
    progress: {
      status: number;
      wasLoaded?: boolean;
    };
  }>(getSlotLoadProgressInfoGql);
  const { progress: slotLoadProgress } = slotLoadProgressInfo!;
  const { i18n } = useTranslation();
  const isIntroScreenToBeSkipped = useReactiveVar(setSkipIntroScreen);
  const proceedToGameAfterLoadProgressPercent = isIntroScreenToBeSkipped ? 100 : 80;
  const isGameLoaded = slotLoadProgress?.status >= proceedToGameAfterLoadProgressPercent;

  const handleIdle = useCallback(
    (state: States) => {
      if (state !== States.IDLE) return;
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'none',
        message: i18n.t('replayBetMessage'),
      });
      setReplayBet('');
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setSkipIntroScreen(true);
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.GAME_READY, () => {
      if (setReplayBet()) {
        eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
        setImmediate(() => {
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          eventManager.on(EventTypes.CHANGE_STATE, (state: States) => {
            handleIdle(state);
          });
        });
      }
    });
  }, [i18n, handleIdle]);

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return <>{slotConfig?.isAuthorized && isGameLoaded ? <GameScreen /> : <LoadScreen />}</>;
};

export default App;
