import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class FadeArea extends ViewContainer {
  private transition: Spine;

  private callback!: () => void;

  constructor() {
    super();
    const transition = new Spine(Loader.shared.resources['lightningTransition']!.spineData!);
    transition.state.timeScale = 0.5;
    this.transition = transition;
    this.zIndex = 3;
    this.addChild(this.transition);
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));
    eventManager.addListener(EventTypes.START_BIG_WIN_PRESENTATION, () => {
      this.interactive = true;
    });
    eventManager.addListener(EventTypes.END_BIG_WIN_PRESENTATION, () => {
      this.interactive = false;
    });
    this.on('mousedown', () => eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION));
    this.on('touchstart', () => eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION));
    this.transition.state.addListener({
      event: (_entry, event) => {
        switch (event.data.name) {
          case 'switch_bg':
            this.callback();
            this.callback = () => ({});
            break;
          default:
        }
      },
    });
  }

  protected override resize(width: number, height: number): void {
    this.transition.width = width;
    this.transition.height = height;
    this.transition.x = width / 2;
    this.transition.y = height / 2;
  }

  private startModeChangeFade(callback: () => void): void {
    this.callback = callback;
    this.transition.state.setAnimation(0, 'transition', false);
  }
}

export default FadeArea;
