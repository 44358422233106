import type React from 'react';
import { useTranslation } from 'react-i18next';

import { paytablePayout } from '../../config/paytable';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PaytableHeader: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoPayTableTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        <div dangerouslySetInnerHTML={{ __html: t('infoPayTableDesc') }} />
      </div>
    </section>
  );
};
const PaytablePayout: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['card']}>
      {[ResourceTypes.payoutIcon1, ResourceTypes.payoutIcon2, ResourceTypes.payoutIcon3].map((res, i) => (
        <div key={paytablePayout[i as number]!.title}>
          <div>
            <img src={Resources.getSource(res)} alt="W-Power" />
            <h3 className={`${styles['p']} ${styles['left']}`}>
              <div
                key={paytablePayout[i as number]!.title}
                dangerouslySetInnerHTML={{ __html: t(paytablePayout[i as number]!.title) }}
              />
            </h3>
          </div>

          <div>
            <p
              key={paytablePayout[i as number]!.desc}
              dangerouslySetInnerHTML={{ __html: t(paytablePayout[i as number]!.desc) }}
            />
          </div>
        </div>
      ))}
    </section>
  );
};

const Paytable: React.VFC = () => {
  return (
    <div className={styles['page']}>
      <PaytableHeader />
      <PaytablePayout />
    </div>
  );
};
export default Paytable;
