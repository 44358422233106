import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { BuyFeatureEnterProps, EventTypes, GameMode, UserBonus } from '../../global.d';
import {
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setIsBuyFeaturePurchased,
  setIsFreeSpinsWin,
  setIsPopupOpened,
  setLastRegularWinAmount,
  setLightningBonusTotalWin,
  setSpecialShotBonusTotalWin,
  setWinAmount,
  setWinCoinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { buyBonusGql } from '../../gql/mutation';
import { eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBonusMode } from '../../utils';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BuyFeatureController extends Controller {
  public gameMode = GameMode.BUY_FEATURE;

  public static the = new BuyFeatureController();

  private constructor() {
    super();
  }

  override enterController(prevGameMode: GameMode, props: BuyFeatureEnterProps): void {
    if (prevGameMode !== GameMode.BASE_GAME) throw new Error('CANT BUY FEATURE NOT IN BASE MODE');
    client
      .mutate<{
        buyBonus: {
          bonus: UserBonus;
          id: string;
          balance: { amount: number; currency: string };
          data: {
            cascadeMultiplier: number;
          };
        };
      }>({
        mutation: buyBonusGql,
        variables: {
          input: {
            id: props?.bonusId,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      })
      .then((res) => {
        const { bonus, balance } = res.data!.buyBonus;
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, balance);
        PopupController.the.closeCurrentPopup();
        setIsBuyFeaturePurchased(false);
        setIsPopupOpened(false);
        setIsFreeSpinsWin(true);
        setLightningBonusTotalWin(0);
        setSpecialShotBonusTotalWin(0);
        setWinAmount(0);
        setLastRegularWinAmount(0);
        setWinCoinAmount(0);
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
        setCurrentBonus({
          ...bonus,
          id: res.data!.buyBonus.id,
          isActive: true,
          currentRound: 0,
        });
        Logic.the.changeGameMode(getBonusMode(props.bonusId), {
          bonus,
        });
      });
  }

  public override exitController(_nextGameMode: GameMode): void {
    AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
  }
}
