import { LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.D1]: { default: ResourceTypes.d1 },
  [SlotId.D2]: { default: ResourceTypes.d2 },
  [SlotId.D3]: { default: ResourceTypes.d3 },
  [SlotId.D4]: { default: ResourceTypes.d4 },
  [SlotId.D5]: { default: ResourceTypes.d5 },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.D1]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'win',
  },
  [SlotId.D2]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'win',
  },
  [SlotId.D3]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'hp_3_win',
  },
  [SlotId.D4]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'hp_4_win',
  },
  [SlotId.D5]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'hp_5_win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.D1]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'land',
  },
  [SlotId.D2]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'land',
  },
  [SlotId.D3]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'hp_3_land',
  },
  [SlotId.D4]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'hp_4_land',
  },
  [SlotId.D5]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'hp_5_land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.d1, src: Resources.getSource(ResourceTypes.d1) },
  { name: ResourceTypes.d2, src: Resources.getSource(ResourceTypes.d2) },
  {
    name: ResourceTypes.d3,
    src: Resources.getSource(ResourceTypes.d3),
  },
  {
    name: ResourceTypes.d4,
    src: Resources.getSource(ResourceTypes.d4),
  },
  { name: ResourceTypes.d5, src: Resources.getSource(ResourceTypes.d5) },
];

export const LOADER_TEXTURES = [
  {
    name: ResourceTypes.gameLogo,
    src: Resources.getSource(ResourceTypes.gameLogo),
  },
  {
    name: ResourceTypes.introGameLogo,
    src: Resources.getSource(ResourceTypes.introGameLogo),
  },
  {
    name: 'blocks',
    src: '/animations/blocks/blocks.json',
  },
  {
    name: 'gameAmbient',
    src: '/animations/gameAmbient/ambient.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/special_messages.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: 'thor',
    src: '/animations/thor/thor_deformed.json',
  },
  {
    name: 'specialThor',
    src: '/animations/specialThor/special_shot_bonus.json',
  },
  {
    name: 'lightningThor',
    src: '/animations/lightningThor/lightning_bonus.json',
  },
  {
    name: 'lightningTransition',
    src: '/animations/lightningTransition/transition.json',
  },
  {
    name: 'lightningBonusButton',
    src: '/animations/lightningBonusButton/button_lb.json',
  },
  {
    name: 'lightningBonusMultipliers',
    src: '/animations/lightningBonusMultipliers/vfx_multiplier_lb.json',
  },
  {
    name: 'landing',
    src: '/animations/landing/landing_animation.json',
  },
  {
    name: 'icons',
    src: '/animations/icons/icons.json',
  },
  {
    name: 'phoenixMovie',
    src: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.maxWin,
    src: Resources.getSource(ResourceTypes.maxWin),
  },
  {
    name: ResourceTypes.lightningBonusBg,
    src: Resources.getSource(ResourceTypes.lightningBonusBg),
  },
  {
    name: ResourceTypes.specialShotBonusBg,
    src: Resources.getSource(ResourceTypes.specialShotBonusBg),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.afterIntroScreen,
    src: Resources.getSource(ResourceTypes.afterIntroScreen),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.buyFeatureBtnDefault,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnDefault),
  },
  {
    name: ResourceTypes.buyFeatureBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.featureBtnDefault,
    src: Resources.getSource(ResourceTypes.featureBtnDefault),
  },
  {
    name: ResourceTypes.featureBtnDisabled,
    src: Resources.getSource(ResourceTypes.featureBtnDisabled),
  },
  {
    name: ResourceTypes.featureBtnHover,
    src: Resources.getSource(ResourceTypes.featureBtnHover),
  },
  {
    name: ResourceTypes.featureBtnPressed,
    src: Resources.getSource(ResourceTypes.featureBtnPressed),
  },
  {
    name: ResourceTypes.featureBtnActive,
    src: Resources.getSource(ResourceTypes.featureBtnActive),
  },
  {
    name: ResourceTypes.lightningBonusIcon,
    src: Resources.getSource(ResourceTypes.lightningBonusIcon),
  },
  {
    name: ResourceTypes.specialShotBonusIcon,
    src: Resources.getSource(ResourceTypes.specialShotBonusIcon),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyButtonDefault,
    src: Resources.getSource(ResourceTypes.buyButtonDefault),
  },
  {
    name: ResourceTypes.buyButtonHover,
    src: Resources.getSource(ResourceTypes.buyButtonHover),
  },
  {
    name: ResourceTypes.buyButtonDisabled,
    src: Resources.getSource(ResourceTypes.buyButtonDisabled),
  },
  {
    name: ResourceTypes.buyButtonPressed,
    src: Resources.getSource(ResourceTypes.buyButtonPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.bonusGamePopup,
    src: Resources.getSource(ResourceTypes.bonusGamePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.buyFeaturePopupBackplate,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupBackplate),
  },
  {
    name: ResourceTypes.invincibleIcon,
    src: Resources.getSource(ResourceTypes.invincibleIcon),
  },
  {
    name: ResourceTypes.wPowerIcon,
    src: Resources.getSource(ResourceTypes.wPowerIcon),
  },
  {
    name: ResourceTypes.payoutIcon1,
    src: Resources.getSource(ResourceTypes.payoutIcon1),
  },
  {
    name: ResourceTypes.payoutIcon2,
    src: Resources.getSource(ResourceTypes.payoutIcon2),
  },
  {
    name: ResourceTypes.payoutIcon3,
    src: Resources.getSource(ResourceTypes.payoutIcon3),
  },
  {
    name: ResourceTypes.mpIcon,
    src: Resources.getSource(ResourceTypes.mpIcon),
  },
  { name: ResourceTypes.hills, src: Resources.getSource(ResourceTypes.hills) },
  {
    name: ResourceTypes.pushBlueDefault,
    src: Resources.getSource(ResourceTypes.pushBlueDefault),
  },
  {
    name: ResourceTypes.pushBlueHover,
    src: Resources.getSource(ResourceTypes.pushBlueHover),
  },
  {
    name: ResourceTypes.pushBluePressed,
    src: Resources.getSource(ResourceTypes.pushBluePressed),
  },
  {
    name: ResourceTypes.pushRedDefault,
    src: Resources.getSource(ResourceTypes.pushRedDefault),
  },
  {
    name: ResourceTypes.pushRedHover,
    src: Resources.getSource(ResourceTypes.pushRedHover),
  },
  {
    name: ResourceTypes.pushRedPressed,
    src: Resources.getSource(ResourceTypes.pushRedPressed),
  },
  {
    name: ResourceTypes.lightning,
    src: Resources.getSource(ResourceTypes.lightning),
  },
];
