import type { EmitterConfigV1 } from '@pixi/particle-emitter';

export const sparkConfig = {
  alpha: {
    start: 1,
    end: 0,
  },
  scale: {
    start: 0.7,
    end: 1,
    minimumScaleMultiplier: 0.7,
  },
  color: {
    start: '#ffffff',
    end: '#ffffff',
  },
  speed: {
    start: 100,
    end: 50,
    minimumSpeedMultiplier: 1,
  },
  acceleration: {
    x: 0,
    y: 0,
  },
  maxSpeed: 0,
  startRotation: {
    min: 0,
    max: 360,
  },
  noRotation: false,
  rotationSpeed: {
    min: 0,
    max: 0,
  },
  lifetime: {
    min: 1,
    max: 1.5,
  },
  blendMode: 'add',
  frequency: 0.01,
  emitterLifetime: -1,
  maxParticles: 500,
  pos: {
    x: 0,
    y: 0,
  },
  addAtBack: false,
  spawnType: 'point',
} as EmitterConfigV1;
