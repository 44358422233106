import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { getFromMappedSymbol } from '../../utils';
import { SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH, eventManager } from '../config';
import type { ThorRewards } from '../d';

const rewardsMap: { [key in ThorRewards]: string } = {
  LIGHTNING_BONUS: 'lightning_bonus_icon',
  SPECIAL_SHOT: 'special_shot_bonus',
  GUARANTEE: 'invincible_icon',
  D_W_POWER: 'w_power',
  MULTIPLIER: 'mp_icon',
};

class Slot extends Sprite {
  public id: number;

  public slotId: SlotId;

  public payoutAmount!: number;

  public textureSlotId!: SlotId;

  public rewardIcon!: Spine;

  public rewardName!: string;

  public rewardValue!: number;

  constructor(id: number, slotId: SlotId, rewardName: ThorRewards) {
    super(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId)));
    this.id = id;
    this.slotId = slotId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.anchor.set(0.5, 0.5);
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = (SLOT_WIDTH / 2) * 1.05;
    let newReward = '';
    if (rewardName?.includes('MULTIPLIER') || rewardName?.includes('GUARANTEE')) {
      const [rewName, rewVal] = rewardName.split('_');
      newReward = rewName as string;
      this.rewardValue = +(rewVal as string);
    } else {
      newReward = rewardName;
    }
    if (newReward) {
      this.rewardIcon = new Spine(Loader.shared.resources['icons']!.spineData!);

      if (newReward.includes('PAYOUT')) {
        this.rewardName = this.payoutIcons(newReward);
      } else {
        this.rewardName = rewardsMap[newReward as string] as string;
      }
      this.rewardIcon.state.setAnimation(0, `${this.rewardName}_static`, true);
      this.rewardIcon.x = -39;
      this.rewardIcon.y = -12;
      this.rewardIcon.zIndex = 2;
      this.addChild(this.rewardIcon);
    }
    this.zIndex = 1;
    eventManager.addListener(EventTypes.RESET_REELS_ANIMATION_END, (removedSlot: Slot) => {
      if (this.rewardIcon && removedSlot.id === this.id) {
        this.rewardIcon.state.setAnimation(0, `${this.rewardName}_land`, false);
      }
    });
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }

  public payoutIcons(payout: ThorRewards): string {
    const payoutAmount: number = +payout.split('_')[1]!;
    this.payoutAmount = payoutAmount;
    if (payoutAmount <= 0.5) {
      return 'payout_icon_1';
    }
    if (payoutAmount >= 1) {
      return 'payout_icon_3';
    }
    return 'payout_icon_2';
  }
}

export default Slot;
