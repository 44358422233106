import type Animation from '../animation';
import AnimationChain from '../animationChain';

export abstract class ReelAnimation extends AnimationChain {
  public abstract getDisappearing(): Animation;
  public abstract getAppearing(): Animation;
  public abstract setDisappearing(tween: Animation): void;
  public abstract setWaiting(tween: Animation): void;
  public abstract setAppearing(tween: Animation): void;
}
