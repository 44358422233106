import { ViewContainer } from '../components/ViewContainer';

import { BackgroundAnimated } from './backgroundAnimated';
import BackgroundStatic from './backgroundStatic';

class Background extends ViewContainer {
  override name = 'Background';

  private backgroundStatic!: BackgroundStatic;

  private backgroundAnimated!: BackgroundAnimated;

  constructor() {
    super();
    this.initBackground();
  }

  private initBackground() {
    this.initStatic();
    this.initAnimated();
  }

  private initStatic() {
    this.backgroundStatic = new BackgroundStatic();
    this.addChild(this.backgroundStatic);
  }

  private initAnimated() {
    this.backgroundAnimated = new BackgroundAnimated();
    this.addChild(this.backgroundAnimated);
  }
}
export default Background;
