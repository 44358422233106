import { GameMode } from '../../global.d';

export const BackgroundAnimatedConfig = {
  [GameMode.BASE_GAME]: {
    animationName: 'base_game_second_plan',
    name: 'Clouds',
  },

  [GameMode.LIGHTNING_BONUS]: {
    animationName: 'lightning_bonus',
    name: 'Lightning',
  },

  [GameMode.SPECIAL_SHOT_BONUS]: {
    animationName: 'special_shot',
    name: 'SpecialShot',
  },
};
