import { States } from '../config';
import { Logic } from '../index';

import { State } from './State';

export class WinCascadePresentation extends State {
  public name: States = States.CASCADE_PRESENTATION;

  public nodes: Map<States, State> = new Map();

  public static the = new WinCascadePresentation();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterCascadeState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitCascadeState(nextState);
  }
}
