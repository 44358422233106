import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, LightningEntryAnimation, UserBonus } from '../../global.d';
import { setAnticipation } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class BackgroundStatic extends ViewContainer {
  override name = 'BackgroundStatic';

  private baseBg!: Texture;

  private lightningBonusBg!: Texture;

  private specialShotBonusBg!: Texture;

  private bgSprite!: Sprite;

  private landing: Spine;

  constructor() {
    super();
    this.init();
    this.landing = this.initLanding();
    this.addChild(this.landing);
    this.landing.state.addListener({
      complete: (entry: LightningEntryAnimation) => {
        switch (entry.animation.name) {
          case 'vfx_landing_animation':
            if (!setAnticipation()) {
              eventManager.emit(EventTypes.START_SPIN_ANIMATION);
            }
            break;
          default:
            break;
        }
      },
    });
    eventManager.on(EventTypes.THOR_REQUEST_BLOCKS_LIGHTNING, () => {
      this.landing.state.setAnimation(0, 'vfx_landing_animation', false);
      AudioApi.play({ type: ISongs.THUNDER, stopPrev: true });
    });
  }

  protected override onBrokenGame(bonus: UserBonus): void {
    switch (bonus.gameMode) {
      case GameMode.LIGHTNING_BONUS:
        this.handleBonusLightningChanges();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.handleSpecialShotBonusChanges();
        break;
      default:
        break;
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.handleBonusLightningChanges();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.handleSpecialShotBonusChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  private init() {
    this.baseBg = Texture.from(ResourceTypes.slotBg);
    this.lightningBonusBg = Texture.from(ResourceTypes.lightningBonusBg);
    this.specialShotBonusBg = Texture.from(ResourceTypes.specialShotBonusBg);

    this.bgSprite = new Sprite(Texture.from(ResourceTypes.slotBg));
    this.bgSprite.name = 'BGStaticSprite';
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
  }

  private initLanding(): Spine {
    const thor = new Spine(Loader.shared.resources['landing']!.spineData!);
    thor.name = 'Landing';
    return thor;
  }

  private handleBaseModeChanges(): void {
    this.bgSprite.texture = this.baseBg;
  }

  private handleBonusLightningChanges(): void {
    this.bgSprite.texture = this.lightningBonusBg;
  }

  private handleSpecialShotBonusChanges(): void {
    this.bgSprite.texture = this.specialShotBonusBg;
  }

  public override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default BackgroundStatic;
