import type React from 'react';
import { useTranslation } from 'react-i18next';

import {
  infoBonusFeatureDescs,
  infoFeatureBuy,
  infoHammerFeatureDescs1,
  infoHammerFeatureDescs2,
  infoLightningBonusFeatureDescs,
  infoMaxWinFeatureDescs,
  infoPowerups,
  infoSpecialShotBonusFeatureDescs,
} from '../../config/features';
import { setSlotConfig } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import { InfoCard } from './components/InfoCard';
import { InfoTextBlock } from './components/InfoTextBlock';
import styles from './info.module.scss';

const HammerFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoHammerFeatureTitle')}</h1>
      <InfoTextBlock texts={infoHammerFeatureDescs1} />
      <InfoTextBlock texts={infoHammerFeatureDescs2} />
    </section>
  );
};

const PowerUps: React.VFC = () => {
  return (
    <section>
      {[
        ResourceTypes.wPowerIcon,
        ResourceTypes.invincibleIcon,
        ResourceTypes.mpIcon,
        ResourceTypes.lightningBonusIcon,
        ResourceTypes.specialShotBonusIcon,
      ].map((res, i) => (
        <InfoCard
          desc={infoPowerups[i as number]!.desc}
          title={infoPowerups[i as number]!.title}
          src={res}
          key={infoPowerups[i as number]!.title}
        />
      ))}
    </section>
  );
};

const BonusFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoBonusFeatureTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoBonusFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const LightningBonusFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('lightningBonus')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoLightningBonusFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const SpecialShotBonusFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('specialShotBonus')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoSpecialShotBonusFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const MaxWinFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoMaxWinFeatureTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']} ${styles['maxWinContainer']}`}>
        <div key={infoMaxWinFeatureDescs[0]} dangerouslySetInnerHTML={{ __html: t(infoMaxWinFeatureDescs[0]!) }} />
        <img
          className={styles['maxWin']}
          draggable="false"
          src={Resources.getSource(ResourceTypes.maxWin)}
          alt="maxWin"
        />
        <div key={infoMaxWinFeatureDescs[1]} dangerouslySetInnerHTML={{ __html: t(infoMaxWinFeatureDescs[1]!) }} />
      </div>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('buyFeatureFunction')}</h1>
      <div className={`${styles['buyfeaturecontainer']}`}>
        <img
          className="buyFeatureBtn"
          draggable="false"
          src={Resources.getSource(ResourceTypes.buyFeatureBtnDefault)}
          alt="buyFeatureBtn"
        />
        <p className={`${styles['featuretext']}`} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
      </div>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoFeatureBuy.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  // const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles['page']}>
      <HammerFeature />
      <PowerUps />
      <BonusFeature />
      <LightningBonusFeature />
      <SpecialShotBonusFeature />
      <MaxWinFeature />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;
