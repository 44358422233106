export class LayoutUtils {
  static letterBox(
    target: { width: number; height: number },
    container: { width: number; height: number },
    options?: {
      top?: number;
      left?: number;
      bottom?: number;
      right?: number;
    },
  ): {
    w: number;
    h: number;
    left: number;
    top: number;
    scale: number;
  } {
    const containerHeight = container.height - (options?.top || 0) - (options?.bottom || 0);
    const containerWidth = container.width - (options?.left || 0) - (options?.right || 0);

    const scale = Math.min(containerWidth / target.width, containerHeight / target.height);

    // the "uniformly englarged" size for our game
    const enlargedWidth = Math.floor(scale * target.width);
    const enlargedHeight = Math.floor(scale * target.height);

    // margins for centering our game
    const horizontalMargin = (containerWidth - enlargedWidth) / 2;
    const verticalMargin = (containerHeight - enlargedHeight) / 2;

    return {
      w: enlargedWidth,
      h: enlargedHeight,
      left: horizontalMargin + (options?.left || 0),
      top: verticalMargin + (options?.top || 0),
      scale: enlargedWidth / target.width,
    };
  }
}
