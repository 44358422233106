import i18n from 'i18next';
import TaggedText from 'pixi-tagged-text';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes, FeatureState, GameMode } from '../../../global.d';
import { setIsBuyFeaturePurchased } from '../../../gql/cache';
import { Logic } from '../../../logic';
import { BaseController } from '../../../logic/controllers/BaseController';
import { ResourceTypes } from '../../../resources.d';
import { SLOT_FEATURES_CONFIG } from '../../../slotFeaturesConfig/slotFeaturesConfig';
import { getBonusIdByFeature } from '../../../utils';
import {
  // bonusTitleConfirmColorTextStyle, // bonusTitleDefaultTextStyle,
  buyFeatureTitleStyleB,
  buyFeatureTitleStyleC,
} from '../../buyFeature/textStyles';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { PopupTypes, eventManager } from '../../config';
import type { BuyFeatureConfirmProps } from '../../d';
import { PopupController } from '../PopupController';
import { Popup } from '../popup';

import { BUY_FEATURE_POPUP_CONFIRM_CONFIG } from './buyFeaturePopupConfirm.config';
import BuyFeaturePopupConfirmBackgroundAnimated from './buyFeaturePopupConfirmBackgroundAnimated';
import BuyFeaturePopupConfirmBackgroundStatic from './buyFeaturePopupConfirmBackgroundStatic';

class BuyFeaturePopupConfirm extends Popup {
  private popupBg!: BuyFeaturePopupConfirmBackgroundStatic | BuyFeaturePopupConfirmBackgroundAnimated;

  private coinAmount: number;

  private featureState: FeatureState = FeatureState.LIGHTNING_BONUS;

  private subtitleText: TaggedText;

  private totalCostText: TextField;

  private totalCostValue: TextField;

  private okBtn: Sprite;

  private cancelBtn: Sprite;

  constructor() {
    super();
    this.visible = false;
    this.coinAmount = 1;
    this.zIndex = 3;
    this.initBackground();
    this.subtitleText = this.initSubtitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose(true);
    });
  }

  public override show(props: BuyFeatureConfirmProps): void {
    super.show();
    const { featureState, coinAmount, totalCost } = props;
    this.subtitleText.setText(
      `${i18n.t<string>(
        featureState === FeatureState.LIGHTNING_BONUS
          ? 'buyFeatureConfirmTitleLightningBonus'
          : 'buyFeatureConfirmTitleSpecialShotBonus',
        { interpolation: { escapeValue: false } },
      )}`,
    );
    this.featureState = featureState;
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public override hide(): void {
    super.hide();
    this.visible = false;
    this.resetButtonsState();
  }

  private resetButtonsState(): void {
    this.removeChild(this.cancelBtn);
    this.removeChild(this.okBtn);
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.addChild(this.cancelBtn);
    this.addChild(this.okBtn);
  }

  private init(): void {
    this.addChild(this.popupBg);
    // this.addChild(this.titleText.getText());
    this.addChild(this.subtitleText);
    this.addChild(this.totalCostText.getText());
    this.addChild(this.totalCostValue.getText());
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initBackground(): void {
    this.popupBg = SLOT_FEATURES_CONFIG.REACT_APP_SLOT_IS_ANIMATED_POPUPS
      ? new BuyFeaturePopupConfirmBackgroundAnimated()
      : new BuyFeaturePopupConfirmBackgroundStatic();
  }

  // private initTitle(): TextField {
  //   const title = new TextField(i18n.t<string>('buyFeatureConfirmTitle'), 1300, 300, {}, true, {
  //     default: bonusTitleDefaultTextStyle as Partial<ITextStyle>,
  //     color: bonusTitleConfirmColorTextStyle as Partial<ITextStyle>,
  //   });
  //   const config = BUY_FEATURE_POPUP_CONFIRM_CONFIG.title;
  //   title.text.y = config.top;
  //   title.text.x = config.x;
  //   title.text.anchor.set(0.5, 0);
  //   return title;
  // }

  private initSubtitle(): TaggedText {
    const subTitle = new TaggedText(
      '',
      {
        default: {
          align: 'center',
          fill: '#acacac',
          fontSize: 48,
          lineHeight: 48,
          wordWrapWidth: 800,
        },
        color: {
          dropShadow: true,
          dropShadowAngle: 7.7,
          dropShadowBlur: 0,
          dropShadowAlpha: 0.5,
          dropShadowColor: '#00b6c7',
          dropShadowDistance: 3,
          fill: '#ffffff',
          fontSize: 58,
          fontWeight: 'bold',
          lineJoin: 'round',
          miterLimit: 0,
          stroke: '#00a8b8',
          strokeThickness: 3,
          whiteSpace: 'normal',
          wordWrapWidth: 800,
          align: 'justify-center',
        },
      },
      { splitStyle: 'words' },
    );
    const config = BUY_FEATURE_POPUP_CONFIRM_CONFIG.subtitle;
    subTitle.y = config.top;
    subTitle.x = config.x;
    subTitle.anchor.set(0, 0);
    return subTitle;
  }

  private initTotalCostText(): TextField {
    const titleCost = new TextField(
      i18n.t<string>('buyFeatureTotalCost'),
      850,
      300,
      buyFeatureTitleStyleB as Partial<ITextStyle>,
    );
    const config = BUY_FEATURE_POPUP_CONFIRM_CONFIG.totalCostTitle;
    titleCost.text.y = config.top;
    titleCost.text.x = config.x;

    titleCost.text.anchor.set(0.5, 0);

    return titleCost;
  }

  private initTotalCostValue(): TextField {
    const totalCostValue = new TextField('0', 500, 100, buyFeatureTitleStyleC as Partial<ITextStyle>);
    const config = BUY_FEATURE_POPUP_CONFIRM_CONFIG.totalCostValue;
    totalCostValue.text.y = config.top;
    totalCostValue.text.x = config.x;
    totalCostValue.text.anchor.set(0.5, 0);
    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
      onTouchStart: () => {
        this.handleClose();
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    });
    const config = BUY_FEATURE_POPUP_CONFIRM_CONFIG.cancelOkBtn;
    cancelBtn.y = config.top + cancelBtn.height / 2;
    cancelBtn.x = config.cancelBtn.left + cancelBtn.width / 2;
    cancelBtn.anchor.set(0.5, 0.5);
    return cancelBtn;
  }

  private initOkBtn(): Sprite {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        AudioApi.play({ type: ISongs.BuyButton });
        this.startBuyFeature();
      },
    });

    const config = BUY_FEATURE_POPUP_CONFIRM_CONFIG.cancelOkBtn;
    okBtn.y = config.top + okBtn.height / 2;
    okBtn.x = config.okBtn.left + okBtn.width / 2;
    okBtn.anchor.set(0.5, 0.5);
    return okBtn;
  }

  private startBuyFeature(): void {
    setIsBuyFeaturePurchased(true);
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
  }

  public handleClose(closeBuyFeature?: boolean): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    if (!closeBuyFeature) {
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      Logic.the.controller = BaseController.the;
      Logic.the.controller.enterController(GameMode.BASE_GAME);
      PopupController.the.closeCurrentPopup();
    }
  }
}

export default BuyFeaturePopupConfirm;
