import { TEXT_GRADIENT, TextStyle } from 'pixi.js';

// const sample = new TextStyle({
//   align: 'center', // string;
//   breakWords: true, // ##false, // boolean;
//   dropShadow: true, // ##false, // boolean;
//   dropShadowAlpha: 0.75, // ##1, // number;
//   dropShadowAngle: 0.785, // ##Math.PI / 6, // number;
//   dropShadowBlur: 10, // ##0, // number;
//   dropShadowColor: 0x000000, // ##'black', // string | number;
//   dropShadowDistance: 4, // number;
//   fill: [0xffc000, 0xff0000], // ##'black', // string | string[] | number | number[] | CanvasGradient | CanvasPattern;
//   fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL, // number;
//   // fillGradientStops: [0,1], //number[];
//   fontFamily: 'NotoSans-SemiCondensedBold', // string | string[];
//   fontSize: 80, // number | string;
//   fontStyle: 'normal', // string;
//   fontVariant: 'normal', // string;
//   fontWeight: 'bold', // ## 'normal', // string;
//   leading: 0, // number;
//   letterSpacing: 0, // number;
//   // lineHeight: 0, //number;
//   lineJoin: 'round', // ##'miter', // string;
//   miterLimit: 10, // number;
//   padding: 4, // ##0, // number;
//   stroke: '#ffffff', // ##'black', // string | number;
//   strokeThickness: 6, // ##0, // number;
//   trim: false, // boolean;
//   textBaseline: 'alphabetic', // string;
//   whiteSpace: 'pre', // string;
//   wordWrap: false, // boolean;
//   wordWrapWidth: 1330, // number;
// });

export const multiplierTitleTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  dropShadowAngle: 1.2,
  // fill: ['#a6925c', '#c8b279', '#ab9363', '#a08b54'],
  // fillGradientStops: [0, 0.4, 0.48, 0.59, 1],
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontSize: 70,
  fontWeight: 'bolder',
  stroke: '#000000',
  strokeThickness: 6,
});

const winMessage = new TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 140,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0.1,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 1.4,
  dropShadowBlur: 7,
  dropShadowColor: '#ffffff',
  dropShadowDistance: 7,
  fill: ['#fddba0', '#ffdc2e', '#ff9500', '#ffd500'],
  fillGradientStops: [0.4],
  fontFamily: 'Arial Black',
  fontSize: 60,
  fontWeight: 'bolder',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#1A262F',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrapWidth: 0,
  letterSpacing: 0.1,
});

export const bottomContainerTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#FCE36E',
};

export const progressTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#ffffff',
  fontSize: 64,
  stroke: '#1A262F',
  strokeThickness: 10,
};

export const bigWinStyles = new TextStyle(winMessage);
export const megaWinStyles = new TextStyle(winMessage);
export const greatWinStyles = new TextStyle(winMessage);
export const epicWinStyles = new TextStyle(winMessage);
export const winValueStyles = new TextStyle(winCounterStyle);

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_FIT_TO_WIDTH = 160;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;

export const WinTextValueTextStyle = new TextStyle({
  dropShadow: true,
  dropShadowAngle: 7.5,
  dropShadowBlur: 13,
  dropShadowColor: '#006c75',
  fill: '#ffffff',
  fontFamily: 'Arial Black',
  fontSize: 100,
  fontWeight: 'bolder',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#1A262F',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
});

export const lightningBonusMultiplierStyle = new TextStyle({
  dropShadowAngle: 7.7,
  dropShadowBlur: 19,
  dropShadowColor: '#f7e038',
  dropShadowDistance: 3,
  fill: '#f7e038',
  fontSize: 250,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
});

export const buyFeatureTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: 7.5,
  dropShadowBlur: 13,
  dropShadowColor: '#006c75',
  fill: '#ffffff',
  fontSize: 42,
  fontWeight: 'bolder',
  lineHeight: 42,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#1e3764',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
});
export const buyFeatureHoverTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: 7.5,
  dropShadowBlur: 13,
  dropShadowColor: '#006c75',
  fill: '#ffffff',
  fontSize: 42,
  fontWeight: 'bolder',
  lineHeight: 42,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#1e3764',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
});
export const buyFeatureClickTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: 7.5,
  dropShadowBlur: 13,
  dropShadowColor: '#006c75',
  fill: '#087d93',
  fontSize: 42,
  fontWeight: 'bolder',
  lineHeight: 42,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#293154',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
});

export const buyFeatureDisableTextStyle = new TextStyle({
  align: 'center',
  dropShadowAngle: 7.5,
  dropShadowBlur: 13,
  dropShadowColor: '#006c75',
  fill: '#989898',
  fontSize: 42,
  fontWeight: 'bolder',
  lineHeight: 42,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#585a5b',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
