import { Emitter, EmitterConfigV3, LinkedListContainer } from '@pixi/particle-emitter';
import type { IDestroyOptions } from 'pixi.js';

export class ParticleEmitterContainer extends LinkedListContainer {
  protected emitter: Emitter;
  protected config: EmitterConfigV3;

  constructor(config: EmitterConfigV3) {
    super();
    this.config = config;
    this.emitter = new Emitter(this, config);
    this.emitter.autoUpdate = true;
  }

  public refresh(): void {
    this.emitter = new Emitter(this, this.config);
    this.emitter.autoUpdate = true;
  }

  public start(): void {
    this.emitter.emit = true;
  }

  public stop(cleanup = false): void {
    this.emitter.emit = false;
    if (cleanup) this.emitter.cleanup();
  }

  public override destroy(options?: boolean | IDestroyOptions): void {
    this.emitter.destroy();
    super.destroy(options);
  }
}
