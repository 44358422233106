const FRAME_WIDTH = 1030;
const CENTER_X = FRAME_WIDTH / 2;
export const BUY_FEATURE_POPUP_CONFIRM_CONFIG = {
  title: {
    x: CENTER_X,
    top: 195,
  },
  subtitle: {
    x: 115,
    top: 170,
  },
  totalCostTitle: {
    x: CENTER_X,
    top: 948,
  },
  totalCostValue: {
    x: CENTER_X,
    top: 995,
  },
  cancelOkBtn: {
    top: 1117,
    cancelBtn: {
      left: 154,
    },
    okBtn: { left: 571 },
  },
};
