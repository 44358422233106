import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [20, 50, 75, 100],
    minBet: 20,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.d1,
      combos: [
        { pattern: 'x5', multiplier: 300 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 30 },
      ],
    },
    {
      slug: ResourceTypes.d2,
      combos: [
        { pattern: 'x5', multiplier: 300 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 30 },
      ],
    },
    {
      slug: ResourceTypes.d3,
      combos: [
        { pattern: 'x5', multiplier: 200 },
        { pattern: 'x4', multiplier: 75 },
        { pattern: 'x3', multiplier: 25 },
      ],
    },
    {
      slug: ResourceTypes.d4,
      combos: [
        { pattern: 'x5', multiplier: 150 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 20 },
      ],
    },
    {
      slug: ResourceTypes.d5,
      combos: [
        { pattern: 'x5', multiplier: 120 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 15 },
      ],
    },
  ],
};

export enum SlotId {
  D1 = 'D1',
  D2 = 'D2',
  D3 = 'D3',
  D4 = 'D4',
  D5 = 'D5',
}
