import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, LightningEntryAnimation } from '../../global.d';
import { setAnticipation, setBetResult, setIsContinueAutoSpinsAfterFeature } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { checkPhoenixAnticipation, generateEqualRandom, getBetResult } from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { buyFeatureClickTextStyle, buyFeatureHoverTextStyle, buyFeatureTextStyle, eventManager } from '../config';

class LightningBonusButton extends ViewContainer {
  public override name = 'LightningBonusButton';

  private btn: Sprite;

  private animation: Spine;

  private text: TextField;

  public isDisabled!: boolean;

  public resizedWidth: number;

  public resizedHeight: number;

  private isFirst: boolean;

  private initial: boolean;

  private clicked = false;

  constructor(width: number, height: number, isFirst: boolean, multiplier: number, initial: boolean) {
    super();
    this.isFirst = isFirst;
    this.initial = initial;
    this.text = this.initLightningBonusText();
    this.btn = this.initLightningPushBtn(isFirst, multiplier);
    if (setIsContinueAutoSpinsAfterFeature()) {
      setTimeout(() => {
        this.onClick();
      }, 100);
    }
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
    this.addChild(this.btn);
    this.animation = this.initButtonAnimation();

    eventManager.addListener(EventTypes.SHOW_LIGHTNING_PUSH_ANIMATION, () => {
      AudioApi.play({ type: ISongs.THUNDER, stopPrev: true });
      this.animation.state.setAnimation(0, 'button_in', false);
      this.animation.state.addListener({
        complete: (entry: LightningEntryAnimation) => {
          switch (entry.animation.name) {
            case 'button_in':
              this.animation.visible = false;
              break;
            default:
              break;
          }
        },
      });
    });
  }

  private initButtonAnimation(): Spine {
    const animation = new Spine(Loader.shared.resources['lightningBonusButton']!.spineData!);
    animation.zIndex = 2;
    this.addChild(animation);
    return animation;
  }

  private initLightningPushBtn(isFirst: boolean, multiplier: number): Sprite {
    const redRandom = generateEqualRandom();
    let isRed = false;
    if (isFirst) {
      if (multiplier >= 5 && redRandom) {
        isRed = true;
      } else {
        isRed = false;
      }
    } else if (multiplier >= 3 && redRandom) {
      isRed = true;
    } else {
      isRed = false;
    }
    const btn = new Sprite(
      Texture.from(isRed && !this.initial ? ResourceTypes.pushRedDefault : ResourceTypes.pushBlueDefault),
    );
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick(isRed));
    btn.on('touchstart', () => this.onClick(isRed));
    btn.zIndex = 1;

    btn.addListener('mouseover', (_e) => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.btn.texture = Texture.from(
        isRed && !this.initial ? ResourceTypes.pushRedHover : ResourceTypes.pushBlueHover,
      );

      this.text.text.style = buyFeatureHoverTextStyle;
    });
    btn.addListener('mouseout', () => {
      this.btn.texture = Texture.from(
        isRed && !this.initial ? ResourceTypes.pushRedDefault : ResourceTypes.pushBlueDefault,
      );

      this.text.text.style = buyFeatureTextStyle;
    });
    btn.addListener('mousedown', () => {
      this.btn.texture = Texture.from(
        isRed && !this.initial ? ResourceTypes.pushRedPressed : ResourceTypes.pushBluePressed,
      );

      this.text.text.style = buyFeatureClickTextStyle;
    });
    btn.addListener('mouseup', () => {
      this.btn.texture = Texture.from(
        isRed && !this.initial ? ResourceTypes.pushRedDefault : ResourceTypes.pushBlueDefault,
      );

      this.text.text.style = buyFeatureTextStyle;
    });
    eventManager.once(EventTypes.SPACEKEY_CLICKED, () => {
      if (this.visible) {
        this.onClick(isRed);
      }
    });
    return btn;
  }

  private initLightningBonusText(): TextField {
    const text = new TextField(i18n.t<string>('lightningBonusButtonPush'), 150, 300, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2);

    return text;
  }

  private onClick(isRed = false): void {
    if (this.clicked) return;
    this.clicked = true;
    const soundToPlayOnClick = isRed && !this.initial ? ISongs.PUSH_DISP2 : ISongs.PUSH_DISP1;

    if (this.initial) {
      AudioApi.play({ type: soundToPlayOnClick, stopPrev: true });
      const showPhoenix = checkPhoenixAnticipation(getBetResult(setBetResult()));
      if (showPhoenix) {
        this.visible = false;
        setAnticipation(true);
        eventManager.emit(EventTypes.PHOENIX_START);
        eventManager.once(EventTypes.PHOENIX_END, () => {
          setAnticipation(false);
          this.visible = true;
          AudioApi.play({ type: soundToPlayOnClick, stopPrev: true });
          eventManager.emit(EventTypes.LIGHTNING_PUSH_CLICKED);
        });
      } else {
        AudioApi.play({ type: soundToPlayOnClick, stopPrev: true });
        eventManager.emit(EventTypes.LIGHTNING_PUSH_CLICKED);
      }
    } else {
      AudioApi.play({ type: soundToPlayOnClick, stopPrev: true });
      eventManager.emit(EventTypes.LIGHTNING_PUSH_CLICKED);
    }
  }

  protected override resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    const isLandscapeMode = this.resizedHeight < this.resizedWidth;
    const scale = isLandscapeMode ? this.resizedHeight / 1000 : this.resizedWidth / 1000;
    this.scale.set(Math.min(scale));
    this.x = this.resizedWidth / 2;
    this.y = (this.resizedHeight * 3) / 4;
  }
}

export default LightningBonusButton;
