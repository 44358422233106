import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { GameMode, UserBonus } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';

import { BackgroundAnimatedConfig } from './backgroundAnimated.config';

export class BackgroundAnimated extends ViewContainer {
  override name = 'BackgroundAnimated';

  private isLandscape = true;

  private spine!: Spine;

  constructor() {
    super();
    this.init();
  }

  protected override onBrokenGame(bonus: UserBonus): void {
    switch (bonus.gameMode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.handleLightningBonusChanges();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.handleSpecialShotBonusChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.handleLightningBonusChanges();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.handleSpecialShotBonusChanges();
        break;
      default:
        this.handleBaseModeChanges();
    }
  }

  private init() {
    const conf = BackgroundAnimatedConfig[GameMode.BASE_GAME] as { animationName: string; name: string };
    this.spine = new Spine(Loader.shared.resources['gameAmbient']!.spineData!);
    this.spine.name = conf.name;
    this.spine.state.setAnimation(0, conf.animationName, true);
    this.addChild(this.spine);
  }

  private handleBaseModeChanges(): void {
    const { animationName } = BackgroundAnimatedConfig[GameMode.BASE_GAME] as { animationName: string; name: string };
    this.spine.state.setAnimation(0, animationName, true);
  }

  private handleLightningBonusChanges(): void {
    const { animationName } = BackgroundAnimatedConfig[GameMode.LIGHTNING_BONUS] as {
      animationName: string;
      name: string;
    };
    this.spine.state.setAnimation(0, animationName, true);
  }

  private handleSpecialShotBonusChanges(): void {
    const { animationName } = BackgroundAnimatedConfig[GameMode.SPECIAL_SHOT_BONUS] as {
      animationName: string;
      name: string;
    };
    this.spine.state.setAnimation(0, animationName, true);
  }

  protected override resize(width: number, height: number): void {
    this.isLandscape = width >= height;

    if (this.spine.state.tracks[0]) {
      const { name } = (this.spine.state.tracks[0] as unknown as { animation: { name: string } }).animation;
      if (this.isLandscape && name.includes('mobile')) {
        this.spine.state.setAnimation(0, name.replace('mobile_', ''), true);
      }
      if (!this.isLandscape && !name.includes('mobile')) {
        this.spine.state.setAnimation(0, `${name}`, true);
      }
    }
    this.x = width / 2;
    this.y = height < 1000 ? height / 2 : 540;
  }
}
