import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setIsSlotBusy,
  setLastRegularWinAmount,
  setSpecialShotBonusTotalWin,
  setWinAmount,
} from '../../gql/cache';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBGMSoundByGameMode, getBetResult, getWinStage, normalizeCoins } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class SpecialShotBonusController extends BaseController {
  public override gameMode: GameMode = GameMode.SPECIAL_SHOT_BONUS;

  public static override the = new SpecialShotBonusController();

  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    setIsSlotBusy(false);
    if (setCurrentBonus().isFinished) {
      AudioApi.fadeOut(1000, getBGMSoundByGameMode(this.gameMode));
      PopupController.the.openPopup(PopupTypes.SPECIAL_SHOT_BONUS_END);
      eventManager.once(EventTypes.END_SPECIAL_SHOT_BONUS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      });
      return;
    }
    setTimeout(() => eventManager.emit(EventTypes.ENTER_BONUS_GAME), 500);
  }

  public override enterSpinState(_prevState: States): void {}

  public override enterBeforeWinState(_prevState: States): void {
    setTimeout(() => Logic.the.changeState(States.WIN_PRESENTATION), 1000);
  }

  public override enterWinPresentationState(_prevState: States): void {
    Logic.the.changeState(States.AFTER_WIN);
  }

  public override enterJingleState(_prevState: States): void {
    Logic.the.changeState(States.IDLE);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.CHANGE_BONUS_GAME_ZINDEX, 0);
    const result = getBetResult(setBetResult());
    if (getWinStage(result.bet.result.winCoinAmount) >= WinStages.BigWin) {
      eventManager.emit(EventTypes.HIDE_COUNT_UP, 0);
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        if (setSpecialShotBonusTotalWin() > 0) {
          const totalWin = setBrokenGame()
            ? setSpecialShotBonusTotalWin()
            : setWinAmount() + setSpecialShotBonusTotalWin();
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, totalWin);
        }
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, result.bet.result.winCoinAmount);
    } else {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        eventManager.emit(EventTypes.HIDE_COUNT_UP);
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
        const multiplier = normalizeCoins(result.bet.result.winCoinAmount) / normalizeCoins(setBetAmount());
        if (multiplier > 7) {
          AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
          return;
        }
        if (multiplier >= 5) {
          AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
          return;
        }
        if (multiplier >= 3) {
          AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
        }
      });
    }
  }

  public override setResult(result: ISettledBet): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_BONUS_GAME_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    setSpecialShotBonusTotalWin(result.bet.result.winCoinAmount);
    setBetResult(result);
    eventManager.emit(EventTypes.SPECIAL_SHOT_BONUS_DATA, result);
  }

  public override enterController(_prevGameMode: GameMode): void {
    eventManager.emit(EventTypes.SPECIAL_THOR_IN);
    if (setBrokenGame()) AudioApi.play({ type: ISongs.BGM_SHOT_Loop });
    setIsSlotBusy(false);
    if (!setWinAmount() || setBrokenGame()) {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    } else {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setWinAmount());
    }

    eventManager.emit(
      EventTypes.HANDLE_UPDATE_BONUS_GAME_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    eventManager.once(EventTypes.START_SPECIAL_SHOT_BONUS_GAME, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    PopupController.the.openPopup(PopupTypes.SPECIAL_SHOT_BONUS);
  }

  public override exitController(_nextGameMode: GameMode): void {
    setLastRegularWinAmount(setSpecialShotBonusTotalWin());
    AudioApi.stop({ type: ISongs.BGM_SHOT_Loop });
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    eventManager.emit(EventTypes.RESET_GAME_PROGRESS, true);
  }
}
