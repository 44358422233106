import { LayoutUtils } from '../../utils/LayoutUtils';
import { ViewContainer } from '../components/ViewContainer';
import type { PopupProps } from '../d';

export abstract class Popup extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 2;
  }

  public hide(): void {}

  public show(_props?: PopupProps): void {}

  override resize(width: number, height: number): void {
    const { left, top, scale } = LayoutUtils.letterBox(
      { width: 1030, height: 1415 },
      { width, height },
      { left: 40, right: 40, top: 10, bottom: 70 },
    );
    this.scale.set(scale);
    this.x = left;
    this.y = top;
  }
}
