import { Container, Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setProgressBarItems } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import { progressTextStyle } from '../config/textStyles';

export class GameProgress extends ViewContainer {
  progress: TextField;

  static progressCounter = 0;

  multiplier: TextField;

  invincible: Sprite;

  wPower: Sprite;

  specialBonus: Sprite;

  lightingBonus: Sprite;

  multiplierSum = 0;

  activeItemsCount = 1;

  iconsContainer: Container;

  constructor() {
    super();
    this.progress = this.initProgress();
    this.multiplier = this.initMultiplier();
    this.iconsContainer = this.initIconsContainer();
    this.addChild(this.iconsContainer);
    this.invincible = this.initInvincible();
    this.wPower = this.initWPower();
    this.specialBonus = this.initSpecialBonus();
    this.lightingBonus = this.initLightingBonus();
    this.addChild(this.progress.getText());
    this.addChild(this.multiplier.getText());

    eventManager.on(EventTypes.UPDATE_GAME_PROGRESS, () => {
      GameProgress.progressCounter += 1;
      this.updateGameProgress();
    });

    eventManager.on(EventTypes.RESET_GAME_PROGRESS, (bonus: boolean) => {
      if (bonus) {
        this.resetProgressAfterBonusGame();
      } else {
        this.resetGameProgress();
      }
    });

    eventManager.on(EventTypes.UPDATE_MULTIPLIER, (multiplier) => {
      this.updateMultiplier(multiplier);
      AudioApi.play({ type: ISongs.ITEM_ICON1, stopPrev: true });
    });

    eventManager.on(EventTypes.UPDATE_GAME_PROGRESS_ICON, (type, state, val) => {
      switch (type) {
        case 'lightning_bonus_icon':
          if (state) {
            AudioApi.play({ type: ISongs.ITEM_ICON2, stopPrev: true });
            this.iconsContainer.addChild(this.lightingBonus);
          } else {
            const idx = this.iconsContainer.getChildIndex(this.lightingBonus);
            this.iconsContainer.removeChildAt(idx);
          }
          this.adjustChildPositions();
          break;
        case 'special_shot_bonus':
          if (state) {
            AudioApi.play({ type: ISongs.ITEM_ICON2, stopPrev: true });
            this.iconsContainer.addChild(this.specialBonus);
          } else {
            const idx = this.iconsContainer.getChildIndex(this.specialBonus);
            this.iconsContainer.removeChildAt(idx);
          }
          this.adjustChildPositions();
          break;
        case 'w_power':
          if (state) {
            AudioApi.play({ type: ISongs.ITEM_ICON1, stopPrev: true });
            this.iconsContainer.addChild(this.wPower);
          } else {
            const idx = this.iconsContainer.getChildIndex(this.wPower);
            this.iconsContainer.removeChildAt(idx);
          }
          this.adjustChildPositions();
          break;
        case 'invincible_icon':
          if (state) {
            AudioApi.play({ type: ISongs.ITEM_ICON1, stopPrev: true });
            this.iconsContainer.addChild(this.invincible);
          } else {
            const idx = this.iconsContainer.getChildIndex(this.invincible);
            this.iconsContainer.removeChildAt(idx);
          }
          this.adjustChildPositions();
          break;
        case 'mp_icon':
          this.updateMultiplier(val);
          AudioApi.play({ type: ISongs.ITEM_ICON1, stopPrev: true });
          break;
        default:
          this.adjustChildPositions();
          break;
      }
    });
  }

  private initIconsContainer(): Container {
    const container = new Container();
    container.y = 160;
    container.x = 30;
    return container;
  }

  private initInvincible(): Sprite {
    const invincible = new Sprite(Texture.from(ResourceTypes.invincibleIcon));
    invincible.name = 'invincible';
    invincible.x = 0;
    invincible.scale.set(isMobile.any ? 0.3 : 0.4);
    return invincible;
  }

  private initWPower(): Sprite {
    const wPower = new Sprite(Texture.from(ResourceTypes.wPowerIcon));
    wPower.name = 'wPower';
    wPower.x = 0;
    wPower.scale.set(isMobile.any ? 0.3 : 0.4);
    return wPower;
  }

  private initSpecialBonus(): Sprite {
    const icon = new Sprite(Texture.from(ResourceTypes.specialShotBonusIcon));
    icon.name = 'specialBonusIcon';
    icon.x = 0;
    icon.scale.set(isMobile.any ? 0.3 : 0.4);
    return icon;
  }

  private initLightingBonus(): Sprite {
    const icon = new Sprite(Texture.from(ResourceTypes.lightningBonusIcon));
    icon.name = 'lightingBonusIcon';
    icon.x = 0;
    icon.scale.set(isMobile.any ? 0.3 : 0.4);
    return icon;
  }

  private initProgress(): TextField {
    const progress = new TextField('0/50', 170, 100, {
      ...progressTextStyle,
    });
    return progress;
  }

  private initMultiplier(): TextField {
    const multiplier = new TextField('', 170, 100, {
      ...progressTextStyle,
    });
    multiplier.text.x = 70;
    multiplier.text.y = 70;
    multiplier.text.anchor.set(0.5, 0);
    return multiplier;
  }

  private updateGameProgress(): void {
    this.progress.setText(`${GameProgress.progressCounter}/50`);
    this.multiplier.text.x = this.progress.width / 2;
  }

  private updateMultiplier(multiplier: number): void {
    this.multiplierSum += multiplier;
    this.multiplier.setText(`x${this.multiplierSum}`);
    this.multiplier.text.x = this.progress.width / 2;
    this.addChild(this.multiplier.getText());
  }

  private adjustChildPositions(): void {
    const names: string[] = [];
    this.iconsContainer.children.forEach((item, index) => {
      const child = item as Sprite | TextField;
      const y = index * (isMobile.any ? 100 : 140);
      if ('name' in child) {
        names.push(child.name);
        child.y = y;
        child.x = isMobile.any ? 25 : 50;
      } else {
        child.text.y = y;
      }
    });

    setProgressBarItems(names);
  }

  private resetGameProgress(): void {
    GameProgress.progressCounter = 0;
    this.updateGameProgress();
    this.resetProgressAfterBonusGame();
  }

  private resetProgressAfterBonusGame(): void {
    this.multiplier.setText('');
    this.multiplierSum = 0;
    this.iconsContainer.removeChildren();
    setProgressBarItems([]);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.LIGHTNING_BONUS:
      case GameMode.SPECIAL_SHOT_BONUS:
        this.visible = false;
        break;
      default:
        this.visible = true;
    }
  }
}
