import { Sprite, Texture } from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';
import { FEATURE_POPUP_HEIGHT, FEATURE_POPUP_WIDTH } from '../../config';

class BuyFeaturePopupBackgroundStatic extends ViewContainer {
  private popupBg: Sprite;

  constructor() {
    super();
    this.popupBg = this.initPopupBg();
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.width = FEATURE_POPUP_WIDTH;
    popupBg.height = FEATURE_POPUP_HEIGHT;

    return popupBg;
  }

  public handleChangeBg(texture: string): void {
    this.popupBg.texture = Texture.from(texture);
  }
}

export default BuyFeaturePopupBackgroundStatic;
