import { ITextStyle, Resource, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { BonusGameResult, EventTypes, GameMode, ISettledBet } from '../../global.d';
import { setCurrentBonus, setIsContinueAutoSpinsAfterFeature } from '../../gql/cache';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';
import { SpecialThorMovements } from '../d';

import SpecialShotBonusButton from './specialShotBonusButton';
import SpecialThor from './specialThor';

const multiplierTextStyles: Partial<ITextStyle> = {
  dropShadowAngle: 7.7,
  dropShadowBlur: 19,
  dropShadowColor: '#f7e038',
  dropShadowDistance: 3,
  fill: '#f7e038',
  fontSize: 100,
  fontWeight: 'bold',
  lineJoin: 'round',
  miterLimit: 0,
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrap: true,
};

class SpecialShotBonus extends ViewContainer {
  public specialThor: SpecialThor;

  public screenHeight = 0;

  public screenWidth = 0;

  public specialShotBonusStarted = false;

  private betResult!: BonusGameResult;

  private totalMultiplier!: number;

  private multiplierText: TextField;

  private finalMultiplier: TextField;

  private specialShotButton!: SpecialShotBonusButton;

  private countUpFinished = false;

  constructor() {
    super();
    this.zIndex = 4;
    this.addClickListener();
    this.finalMultiplier = this.initFinalMultiplierText();
    this.specialThor = this.initSpecialThor();
    this.multiplierText = this.initMultiplierText();
    this.addChild(this.specialThor.getSpecialThor);
    this.specialThor.getSpecialThor.addChild(this.multiplierText.getText());
    this.initSpecialShotBonusButton();

    eventManager.addListener(EventTypes.SPECIAL_SHOT_BONUS_DATA, (betResult: ISettledBet) => {
      this.betResult = betResult.bet.data.features.specialShotLotteryResult;
      this.totalMultiplier = +this.betResult.value.split('_')[1]! as number;
      this.specialThor.setMultiplier = this.totalMultiplier;
      this.finalMultiplier.setText(`x${this.totalMultiplier}`);
      this.multiplierText.setText(`x${this.totalMultiplier}`);
      const animation = new Tween({
        object: this.specialThor.getSpecialThor,
        property: TweenProperties.Y,
        propertyBeginValue: this.screenHeight / 2,
        target: this.screenHeight / 2.5,
        duration: 300,
      });
      animation.start();
      animation.addOnComplete(() => {
        this.specialShotButton.visible = true;
        if (setIsContinueAutoSpinsAfterFeature()) {
          setTimeout(() => {
            this.specialShotButton.onClick();
          }, 100);
        }
      });
    });
    eventManager.addListener(EventTypes.CHANGE_BONUS_GAME_ZINDEX, (zIndex: number) => {
      this.zIndex = zIndex;
      this._cleanUp();
    });
    eventManager.addListener(EventTypes.SPECIAL_SHOT_PUSH_CLICKED, () => {
      this.startAnimation();
    });
    eventManager.addListener(EventTypes.START_BIG_WIN_PRESENTATION, () => {
      this._cleanUp();
    });
  }

  private _cleanUp(): void {
    this.multiplierText.text.visible = false;
    this.multiplierText.setText('');
    this.totalMultiplier = 0;
    this.finalMultiplier.setText('');
  }

  private initMultiplierText(): TextField {
    const multiplierText = new TextField('', 300, 300, multiplierTextStyles);
    multiplierText.text.visible = false;
    multiplierText.text.anchor.set(0.5);
    multiplierText.text.x = 6.5;
    multiplierText.text.y = 15.5;
    return multiplierText;
  }

  private initFinalMultiplierText(): TextField {
    const finalMultiplier = new TextField(`x${200}`, 300, 300, multiplierTextStyles);
    finalMultiplier.text.x = -20;
    finalMultiplier.text.anchor.set(0.5);

    return finalMultiplier;
  }

  private initSpecialThor(): SpecialThor {
    const lightningThor = new SpecialThor();
    lightningThor.getSpecialThor.visible = false;

    setTimeout(() => {
      const sprite = lightningThor.getSpecialThor.skeleton.slots.find(
        (slot) => slot.currentSpriteName === 'place_holder',
      );
      if (sprite) {
        (sprite.currentSprite as unknown as { texture: Texture<Resource> }).texture = Texture.EMPTY;
        (sprite.currentSprite as unknown as { addChild: CallableFunction }).addChild(this.finalMultiplier.getText());
      }
    }, 0);
    return lightningThor;
  }

  private onClick(): void {
    if (this.specialShotBonusStarted) return;
    this.specialShotBonusStarted = false;
    this.specialThor.getSpecialThor.visible = true;
    eventManager.emit(EventTypes.SPECIAL_THOR_START);
    this.removeClickListener();
  }

  private addClickListener(): void {
    this.interactive = true;
    this.on('click', () => this.onClick());
    this.on('touchstart', () => this.onClick());
  }

  private removeClickListener(): void {
    this.interactive = false;
    this.removeAllListeners();
  }

  private initSpecialShotBonusButton(): void {
    const specialShotbonusButton = new SpecialShotBonusButton(this.screenWidth, this.screenHeight);
    specialShotbonusButton.visible = false;
    this.specialShotButton = specialShotbonusButton;
    this.addChild(this.specialShotButton);
  }

  private setPositions(): void {
    const isLandscapeMode = this.screenHeight < this.screenWidth;
    if (isMobile.any) {
      this.specialThor.getSpecialThor.scale.set(
        Math.min(1.5, isLandscapeMode ? this.screenHeight / 700 : this.screenWidth / 400),
      );
    } else {
      this.specialThor.getSpecialThor.scale.set(
        Math.min(1.5, isLandscapeMode ? this.screenHeight / 700 : this.screenWidth / 700),
      );
    }
    this.specialThor.getSpecialThor.position.set(
      this.screenWidth / 2,
      this.betResult ? this.screenHeight / 2.5 : this.screenHeight / 2,
    );
  }

  protected override resize(width: number, height: number): void {
    this.screenWidth = width;
    this.screenHeight = height;
    this.setPositions();
  }

  private setWinValue(multiply: number): void {
    const fixedMultiply = +multiply.toFixed();
    this.multiplierText.setText(`x${fixedMultiply}`);
  }

  private executeSpecialShot(currentMultiply: number): void {
    if (!this.countUpFinished) {
      this.countUpFinished = true;
      const countUpAnimation = new Tween({
        propertyBeginValue: currentMultiply,
        target: this.totalMultiplier,
        object: this.multiplierText.getText(),
        property: TweenProperties.WIN_VALUE,
        update: this.setWinValue.bind(this),
        duration: 2000,
      });

      AudioApi.stop({ type: ISongs.BOOMERANG });
      AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP1 });
      AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
      AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
      AudioApi.play({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });

      eventManager.emit(EventTypes.EXECUTE_SPECIAL_SHOT);

      countUpAnimation.start();
      countUpAnimation.addOnComplete(() => {
        AudioApi.stop({ type: ISongs.HAMMER_THROWING });
        AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
        AudioApi.play({ type: ISongs.SHOT_FIRE });

        setCurrentBonus({
          ...setCurrentBonus(),
          isFinished: true,
        });
        setTimeout(() => Logic.the.changeState(States.BEFORE_WIN), 3000);
        this.multiplierText.setText(``);
      });
    }
  }

  private startAnimation(): void {
    this.specialThor.startSpecialThorAnimation();
    this.multiplierText.setText(`x${this.totalMultiplier}`);
    const maxMultiplier = Math.min(10, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 1,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2700,
    });
    this.multiplierText.text.visible = true;
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.INIT_DISP });
    AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP1 });

    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 10) {
        this.enter10Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter10Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP1 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });

    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.defaultThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP1 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER1 });

      this.start50Animation();
    });
  }

  private start50Animation(): void {
    const maxMultiplier = Math.min(50, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 11,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 50) {
        this.enter50Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter50Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP1 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.yellowThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start100Animation();
    });
  }

  private start100Animation(): void {
    const maxMultiplier = Math.min(100, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 51,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 100) {
        this.enter100Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter100Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start200Animation();
    });
  }

  private start200Animation(): void {
    const maxMultiplier = Math.min(200, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 101,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 200) {
        this.enter200Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter200Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start300Animation();
    });
  }

  private start300Animation(): void {
    const maxMultiplier = Math.min(300, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 201,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 300) {
        this.enter300Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter300Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start400Animation();
    });
  }

  private start400Animation(): void {
    const maxMultiplier = Math.min(400, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 301,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 400) {
        this.enter400Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter400Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start500Animation();
    });
  }

  private start500Animation(): void {
    const maxMultiplier = Math.min(500, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 401,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 500) {
        this.enter500Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter500Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start600Animation();
    });
  }

  private start600Animation(): void {
    const maxMultiplier = Math.min(600, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 501,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 600) {
        this.enter600Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter600Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start700Animation();
    });
  }

  private start700Animation(): void {
    const maxMultiplier = Math.min(700, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 601,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 700) {
        this.enter700Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter700Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start800Animation();
    });
  }

  private start800Animation(): void {
    const maxMultiplier = Math.min(800, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 701,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 800) {
        this.enter800Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter800Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start900Animation();
    });
  }

  private start900Animation(): void {
    const maxMultiplier = Math.min(900, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 801,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 900) {
        this.enter900Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter900Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.greenThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP2 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER2 });

      this.start1000Animation();
    });
  }

  private start1000Animation(): void {
    const maxMultiplier = Math.min(1000, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 901,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 1000) {
        this.enter1000Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter1000Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP2 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });

      this.start1500Animation();
    });
  }

  private start1500Animation(): void {
    const maxMultiplier = Math.min(1500, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 1001,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 1500) {
        this.enter1500Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter1500Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.start2000Animation();
    });
  }

  private start2000Animation(): void {
    const maxMultiplier = Math.min(2000, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 1501,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 2000) {
        this.enter2000Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter2000Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.start2500Animation();
    });
  }

  private start2500Animation(): void {
    const maxMultiplier = Math.min(2500, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 2001,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 2500) {
        this.enter2500Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter2500Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.start3000Animation();
    });
  }

  private start3000Animation(): void {
    const maxMultiplier = Math.min(3000, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 2501,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 3000) {
        this.enter3000Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter3000Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.start3500Animation();
    });
  }

  private start3500Animation(): void {
    const maxMultiplier = Math.min(3500, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 3001,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 3500) {
        this.enter3500Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter3500Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.start4000Animation();
    });
  }

  private start4000Animation(): void {
    const maxMultiplier = Math.min(4000, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 3501,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 4000) {
        this.enter4000Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter4000Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.start4500Animation();
    });
  }

  private start4500Animation(): void {
    const maxMultiplier = Math.min(4500, this.totalMultiplier);
    const target = maxMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 4001,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();
    countUpAnimation.addOnComplete(() => {
      if (this.totalMultiplier > 4500) {
        this.enter4500Threshold(target);
      } else {
        this.executeSpecialShot(target);
      }
    });
  }

  private enter4500Threshold(target: number): void {
    const countUpAnimation = new Tween({
      propertyBeginValue: target,
      target: target + 5,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 2000,
    });
    countUpAnimation.start();
    AudioApi.play({ type: ISongs.HAMMER_THROWING });

    AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
    AudioApi.play({ type: ISongs.HEARTBEAT_LOOP });
    this.specialThor.setSpecialThorAnimations(SpecialThorMovements.redThreshold, false);
    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.HEARTBEAT_LOOP });
      AudioApi.stop({ type: ISongs.HAMMER_THROWING });
      AudioApi.play({ type: ISongs.BOOMERANG });
      AudioApi.play({ type: ISongs.SHOT_COUNT_UP_LOOP3 });

      AudioApi.play({ type: ISongs.RECOUNT_UP });
      AudioApi.play({ type: ISongs.BUILD_UP_POWER3 });
      this.startFinalAnimation();
    });
  }

  private startFinalAnimation(): void {
    const target = this.totalMultiplier - 5;
    const countUpAnimation = new Tween({
      propertyBeginValue: 4501,
      target,
      object: this.multiplierText.getText(),
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration: 3000,
    });
    countUpAnimation.start();

    countUpAnimation.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SHOT_COUNT_UP_LOOP3 });
      this.executeSpecialShot(target);
    });
  }

  private cleanUp(): void {
    this.specialThor.getSpecialThor.position.y = this.screenHeight / 2;
    this.countUpFinished = false;
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.specialShotBonusStarted = false;
        this.specialThor.getSpecialThor.visible = false;
        this.specialShotButton.visible = false;
        this.specialShotButton.clicked = false;
        this.removeClickListener();
        break;
      case GameMode.LIGHTNING_BONUS:
        this.specialShotBonusStarted = false;
        this.specialThor.getSpecialThor.visible = false;
        this.specialShotButton.visible = false;
        this.specialShotButton.clicked = false;
        this.removeClickListener();
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.specialThor.getSpecialThor.visible = true;
        this.zIndex = 4;
        this.addClickListener();
        break;
      default:
        this.specialShotBonusStarted = false;
        this.specialThor.getSpecialThor.visible = false;
        this.specialShotButton.visible = false;
        this.specialShotButton.clicked = false;
        this.removeClickListener();
    }
    this.cleanUp();
  }
}
export default SpecialShotBonus;
