export enum ResourceTypes {
  bonusGamePopup = 'bonusGamePopup',
  buyButtonDefault = 'buyButtonDefault',
  buyButtonDisabled = 'buyButtonDisabled',
  buyButtonHover = 'buyButtonHover',
  buyButtonPressed = 'buyButtonPressed',
  buyFeatureConfirmPopupBackplate = 'buyFeatureConfirmPopupBackplate',
  buyFeaturePopupBackplate = 'buyFeaturePopupBackplate',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureBtnDefault = 'buyFeatureBtnDefault',
  buyFeatureBtnDisabled = 'buyFeatureBtnDisabled',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  featureBtnActive = 'featureBtnActive',
  featureBtnDefault = 'featureBtnDefault',
  featureBtnDisabled = 'featureBtnDisabled',
  featureBtnHover = 'featureBtnHover',
  featureBtnPressed = 'featureBtnPressed',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  gameLogo = 'gameLogo',
  introGameLogo = 'introGameLogo',
  hills = 'hills',
  invincibleIcon = 'invincibleIcon',
  lightningBonusIcon = 'lightningBonusIcon',
  mpIcon = 'mpIcon',
  payoutIcon1 = 'payoutIcon1',
  payoutIcon2 = 'payoutIcon2',
  payoutIcon3 = 'payoutIcon3',
  specialShotBonusIcon = 'specialShotBonusIcon',
  wPowerIcon = 'wPowerIcon',
  afterIntroScreen = 'afterIntroScreen',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  lightningBonusBg = 'lightningBonusBg',
  lightning = 'lightning',
  pushBlueDefault = 'pushBlueDefault',
  pushBlueHover = 'pushBlueHover',
  pushBluePressed = 'pushBluePressed',
  pushRedDefault = 'pushRedDefault',
  pushRedHover = 'pushRedHover',
  pushRedPressed = 'pushRedPressed',
  logo = 'logo',
  maxWin = 'maxWin',
  x10 = 'x10',
  x100 = 'x100',
  x2 = 'x2',
  x20 = 'x20',
  x3 = 'x3',
  x30 = 'x30',
  x5 = 'x5',
  x50 = 'x50',
  x70 = 'x70',
  slotBg = 'slotBg',
  specialShotBonusBg = 'specialShotBonusBg',
  d1 = 'd1',
  d2 = 'd2',
  d3 = 'd3',
  d4 = 'd4',
  d5 = 'd5',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
}
