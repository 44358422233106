import { Sprite, Texture, isMobile } from 'pixi.js';

import { EventTypes, GameMode, UserBonus } from '../../global.d';
import { setIsLeftHandMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class Logo extends ViewContainer {
  public gameLogo: Sprite;

  private isLeftHandMode: boolean;

  private isPortraitMode!: boolean;

  private isLandscapeMode!: boolean;

  public applicationSize: { width: number; height: number };

  constructor() {
    super();
    this.applicationSize = { width: 0, height: 0 };
    this.gameLogo = this.initGameLogo();
    this.isLeftHandMode = setIsLeftHandMode();

    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });

    this.addChild(this.gameLogo);
  }

  private initGameLogo(): Sprite {
    const gameLogo = new Sprite(Texture.from(ResourceTypes.gameLogo));
    gameLogo.name = 'gameLogo';
    gameLogo.width = 617;
    gameLogo.height = 299;
    gameLogo.x = -41;
    gameLogo.y = 16;

    return gameLogo;
  }

  protected override onBrokenGame(bonus: UserBonus): void {
    switch (bonus.gameMode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.LIGHTNING_BONUS:
        this.visible = false;
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.visible = false;
        break;
      default:
        this.visible = true;
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.LIGHTNING_BONUS:
        this.visible = false;
        break;
      case GameMode.SPECIAL_SHOT_BONUS:
        this.visible = false;
        break;
      default:
        this.visible = true;
    }
  }

  private handlePosition(): void {
    if (isMobile.any && this.isLandscapeMode) {
      this.gameLogo.scale.set(Math.min(0.6, this.applicationSize.height / 1000));
      if (this.isLeftHandMode) {
        this.gameLogo.scale.set(Math.min(0.4, this.applicationSize.height / 1000));
        this.gameLogo.anchor.set(1, 0);
        this.gameLogo.x = this.applicationSize.width;
      } else {
        this.gameLogo.scale.set(Math.min(0.6, this.applicationSize.height / 1000));
        this.gameLogo.anchor.set(0, 0);
        this.gameLogo.x = 0;
      }

      this.gameLogo.y = 0;
    } else if (isMobile.any && this.isPortraitMode) {
      this.gameLogo.scale.set(Math.min(0.5, this.applicationSize.height / 1920));
      this.gameLogo.anchor.set(1, 0);
      this.gameLogo.x = this.applicationSize.width;
      this.gameLogo.y = -(this.gameLogo.width * 0.05);
    } else {
      this.gameLogo.scale.set(this.applicationSize.height / 1080);
      this.gameLogo.anchor.set(0, 0);
      this.gameLogo.x = 0;
      this.gameLogo.y = 0;
    }
  }

  protected override resize(width: number, height: number): void {
    this.applicationSize.width = width;
    this.applicationSize.height = height;
    this.isLandscapeMode = width >= height;
    this.isPortraitMode = width < height;

    this.handlePosition();
  }
}

export default Logo;
