import type { Group } from '@pixi/layers';

import type { SlotId } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setBetResult } from '../../../gql/cache';
import { getBetResult } from '../../../utils';
import { CascadeAnimation } from '../../animations/reel/cascadeAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import { ViewContainer } from '../../components/ViewContainer';
import { REEL_WIDTH, ReelState, SLOT_WIDTH, eventManager } from '../../config';
import type { Block, Icon, ThorRewards } from '../../d';
import Slot from '../slot';

import type { Reel } from './reel';

class CascadeReel extends ViewContainer implements Reel {
  public id!: number;

  public state: ReelState;

  public data: Block[];

  public animation: ReelAnimation | null = null;

  public slots: Slot[] = [];

  public isPlaySoundOnStop = false;

  public size: number;

  constructor(data: Block[], cascades: Icon[], slotGroup: Group) {
    super();
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.sortableChildren = true;
    this.x = (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.y = 0;
    eventManager.on(EventTypes.CLEAN_UP_REEL, () => {
      this.clean();
    });
    this.createSlots(
      cascades.map((cascade) => cascade.id),
      slotGroup,
    );
  }

  public clean(): void {
    this.removeChildren();
    this.slots = [];
  }

  public init(data: Block[]): void {
    this.data = data;
    this.size = data.length;
  }

  public createSlots(slots: SlotId[], slotGroup: Group): void {
    if (setBetResult()) {
      const { generatedReelSet } = getBetResult(setBetResult()).bet.data.features;
      this.data = generatedReelSet.map((e) => ({
        slotId: e.iconId as SlotId,
        rewardName: e.rewardName as ThorRewards,
      }));
      this.size = this.data.length;
    }

    this.slots = [];
    this.removeChildren();
    for (let i = 0; i < slots.length; i++) {
      const slot = new Slot(slots.length - i - 1, slots[i as number]!, this.data[i as number]!.rewardName);
      this.slots.push(slot);
      slot.parentGroup = slotGroup;
      this.addChild(slot);
    }
  }

  public createSpinAnimation(): ReelAnimation {
    this.animation = new CascadeAnimation({});
    return this.animation;
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStop(): void {}

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.APPEARING) {
      this.onReelStop();
    }
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.DISAPPEARING) {
      this.onReelRolling(previousState, ReelState.DISAPPEARING);
    }
    if (newState === ReelState.WAITING) {
      this.onReelStarting(previousState, ReelState.WAITING);
    }
    if (newState === ReelState.APPEARING) {
      this.onReelEnding(previousState, ReelState.APPEARING);
    }
  }
}

export default CascadeReel;
