import { upgradeConfig } from '@pixi/particle-emitter';
import { IPointData, Texture } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { eventManager } from '../../config';
import AnimationGroup from '../animationGroup';
import { TweenProperties } from '../d';
import { ParticleEmitterContainer } from '../particles';
import Tween from '../tween';

import { sparkConfig } from './sparkConfig';

export class Track extends ParticleEmitterContainer {
  private animation?: AnimationGroup | undefined;

  constructor() {
    super(upgradeConfig(sparkConfig, Texture.from(ResourceTypes.lightning)));
    this.stop(true);

    eventManager.on(EventTypes.START_TRACK_ANIMATION, (start, end, duration, cb) => {
      this.stop();
      this.refresh();
      this.startTrackAnimation(start, end, duration, cb);
    });
    eventManager.on(EventTypes.SKIP_TRACK_ANIMATION, () => {
      const anim = this.animation;
      this.animation = undefined;
      anim?.skip();
      this.stop(true);
    });
  }

  private startTrackAnimation(start: IPointData, end: IPointData, duration: number, cb?: () => void) {
    const xTween = new Tween({
      object: this.emitter.spawnPos,
      property: TweenProperties.X,
      propertyBeginValue: start.x,
      target: end.x,
      duration,
    });
    const yTween = new Tween({
      object: this.emitter.spawnPos,
      property: TweenProperties.Y,
      propertyBeginValue: start.y,
      target: end.y,
      duration,
    });
    const anim = new AnimationGroup();
    this.animation = anim;
    anim.addAnimation(xTween);
    anim.addAnimation(yTween);
    anim.addOnStart(() => {
      this.stop(true);
      this.start();
    });
    anim.addOnComplete(() => {
      if (this.animation) {
        this.stop();
        cb?.();
      }
    });
    anim.start();
  }
}
