import type { Container } from 'pixi.js';

import { cascadeEase } from '../../utils';
import type { TextField } from '../components/TextField';
import type Slot from '../reels/slot';

import AnimationChain from './animationChain';
import AnimationGroup from './animationGroup';
import { TweenProperties } from './d';
import Tween from './tween';

export const createZoomAnimation = (
  object: TextField,
  target: number,
  propertyBeginValue: number,
  duration: number,
  isLoop: boolean,
): AnimationChain => {
  const animationChain = new AnimationChain({ isLoop });
  const zoomInAnimationGroup = new AnimationGroup({});
  const zoomOutAnimationGroup = new AnimationGroup({});
  const zoomInX = new Tween({
    object: object.text.scale,
    target,
    property: TweenProperties.X,
    propertyBeginValue,
    duration: duration / 2,
  });
  const zoomInY = new Tween({
    object: object.text.scale,
    target,
    property: TweenProperties.Y,
    propertyBeginValue,
    duration: duration / 2,
  });

  zoomInAnimationGroup.addAnimation(zoomInX);
  zoomInAnimationGroup.addAnimation(zoomInY);

  const zoomOutX = new Tween({
    object: object.text.scale,
    target: propertyBeginValue,
    property: TweenProperties.X,
    propertyBeginValue: target,
    duration: duration / 2,
  });

  const zoomOutY = new Tween({
    object: object.text.scale,
    target: propertyBeginValue,
    property: TweenProperties.Y,
    propertyBeginValue: target,
    duration: duration / 2,
  });

  zoomOutAnimationGroup.addAnimation(zoomOutX);
  zoomOutAnimationGroup.addAnimation(zoomOutY);
  animationChain.appendAnimation(zoomInAnimationGroup);
  animationChain.appendAnimation(zoomOutAnimationGroup);

  return animationChain;
};

export const createLightningMultiplierAppear = (
  object: Container,
  targetX: number,
  targetY: number,
  propertyBeginValueX: number,
  propertyBeginValueY: number,
  duration: number,
  _isLoop: boolean,
): AnimationGroup => {
  // const animationChain = new AnimationChain({ isLoop });
  const appearAnimationGroup = new AnimationGroup({});

  const appearX = new Tween({
    object,
    property: TweenProperties.X,
    propertyBeginValue: propertyBeginValueX,
    target: targetX,
    duration,
  });

  const appearY = new Tween({
    object,
    property: TweenProperties.Y,
    propertyBeginValue: propertyBeginValueY,
    target: targetY,
    duration,
  });

  appearAnimationGroup.addAnimation(appearX);
  appearAnimationGroup.addAnimation(appearY);

  // animationChain.appendAnimation(appearAnimationGroup);
  return appearAnimationGroup;
};

export const createBlockLandingAnimation = (
  object: Slot,
  target: number,
  propertyBeginValue: number,
  duration: number,
  isLoop: boolean,
): AnimationChain => {
  const animationChain = new AnimationChain({ isLoop });

  const appearing = new Tween({
    object,
    property: TweenProperties.Y,
    propertyBeginValue,
    target,
    duration,
    easing: object.id < 4 ? cascadeEase : (n) => n,
  });
  const landIn = new Tween({
    object,
    property: TweenProperties.Y,
    propertyBeginValue: target,
    target: target + 50,
    duration: 150,
  });
  const landOut = new Tween({
    object,
    property: TweenProperties.Y,
    propertyBeginValue: target + 50,
    target: target - 25,
    duration: 150,
  });
  const landOver = new Tween({
    object,
    property: TweenProperties.Y,
    propertyBeginValue: target - 25,
    target,
    duration: 150,
  });

  animationChain.appendAnimation(appearing);
  animationChain.appendAnimation(landIn);
  animationChain.appendAnimation(landOut);
  animationChain.appendAnimation(landOver);

  return animationChain;
};
