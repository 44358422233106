import { EventTypes } from '../../global.d';
import { calcBottomContainerHeight } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 1;
    this.interactive = true;
  }

  private calculateSize(width: number, height: number): number[] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomSpace = bottomContainerHeight;
    const isLandscape = width >= height;
    const isPortrait = width < height;

    if (isLandscape) {
      newWidth = width;
      newHeight = height - bottomSpace;

      return [newWidth, newHeight, bottomSpace];
    }

    if (isPortrait) {
      newWidth = width;
      newHeight = (height - bottomSpace) * 0.7;
    }

    return [newWidth, newHeight, bottomSpace];
  }

  protected override resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);
    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, {
      appWidth: width,
      appHeight: height,
      gameContainerWidth: newWidth,
      gameContainerHeight: newHeight,
      underGameContainerSpaceHeight: height - (newHeight as number) - (bottomSpace as number),
    });
  }
}
export default SafeArea;
