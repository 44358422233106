export const gameRules1 = ['infoGameRules1_1', 'infoGameRules1_2'];
export const gameRules2 = [
  'infoGameRules2_1',
  'infoGameRules2_2',
  'infoGameRules2_3',
  'infoGameRules2_4',
  'infoGameRules2_5',
];

export const gameRules3 = ['infoGameRules3_1', 'infoGameRules3_2', 'infoGameRules3_3', 'infoGameRules3_4'];
