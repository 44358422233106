import _ from 'lodash';

import type { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BGM_BG_Base_Loop = '012_01_BaseGame_BGM_Base',
  BGM_BG_Melo_Loop = '012_02_BaseGame_BGM_Melo',
  BGM_LIGHT_Loop = '012_04_LIGHT_BGM_Loop',
  LIGHT_ExitPopup = '012_05_TotalWinBanner_LIGHT',
  BGM_SHOT_Loop = '012_07_SHOT_BGM_Loop',
  SHOT_ExitPopup = '012_08_TotalWinBanner_SHOT',
  BigWin_Loop = '012_09_BigWin_Loop',
  BigWin_End = '012_10_BigWin_END',
  SWING = '012_11_swing',
  BROKEN1 = '012_12_broken1',
  BROKEN2 = '012_13_broken2',
  FAIL = '012_14_fail',
  BLOCK_LAND = '012_15_block_land',
  EFFECT_MOVE1 = '012_16_effect_move1',
  EFFECT_MOVE2 = '012_17_effect_move2',
  POWER_UP = '012_18_powerup',
  INVINCIBLE_START = '012_19_invincibleSTART',
  INVINCIBLE_END = '012_20_invincibleEND',
  ITEM_ICON1 = '012_21_item_icon1',
  ITEM_ICON2 = '012_22_item_icon2',
  PUSH_DISP1 = '012_23_PUSH_disp1',
  PUSH_DISP2 = '012_24_PUSH_disp2',
  INIT_DISP = '012_25_INIT_disp',
  MULTIPLIER_SPLITS = '012_26_Multiplier_splits',
  MULTIPLIER_GATGERING = '012_28_Multiplier_gathering',
  MULTIPLIER_RESULT_DISPLAY = '012_29_MPresult_display',
  THUNDER = '012_27_thunder',
  SHOT_COUNT_UP_LOOP1 = '012_30_SHOT_countup_Loop1',
  SHOT_COUNT_UP_LOOP2 = '012_31_SHOT_countup_Loop2',
  SHOT_COUNT_UP_LOOP3 = '012_32_SHOT_countup_Loop3',
  HEARTBEAT_LOOP = '012_33_heartbeat_Loop',
  RECOUNT_UP = '012_34_recount_up',
  SHOT_FIRE = '012_35_SHOT_fire',
  BUILD_UP_POWER1 = '012_36_build_up_power1',
  BUILD_UP_POWER2 = '012_37_build_up_power2',
  BUILD_UP_POWER3 = '012_38_build_up_power3',
  HAMMER_THROWING = '012_40_hammer_throwing',
  BOOMERANG = '012_41_Boomerang',
  SmallWin = '013_14_SmallWin',
  MediumWin = '013_15_MediumWin',
  HighWin = '013_16_HighWin',
  SFX_WIN_MAX = 'SFX_WIN_MAX',
  SFX_phoenix = 'SFX_phoenix',
  BuyButton = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger',
  Win_Big = 'SFX_Win_Big',
  Win_Epic = 'SFX_Win_Epic',
  Win_Great = 'SFX_Win_Great',
  Win_Mega = 'SFX_Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_BG_Base_Loop]: [0, 58911.92743764172, true],
  [ISongs.BGM_BG_Melo_Loop]: [60000, 58911.92743764172, true],
  [ISongs.BGM_LIGHT_Loop]: [120000, 41685.62358276643, true],
  [ISongs.LIGHT_ExitPopup]: [163000, 5728.072562358278],
  [ISongs.BGM_SHOT_Loop]: [170000, 30238.18594104307, true],
  [ISongs.SHOT_ExitPopup]: [202000, 7698.866213151916],
  [ISongs.BigWin_Loop]: [211000, 32000, true],
  [ISongs.BigWin_End]: [244000, 4046.122448979588],
  [ISongs.SWING]: [250000, 341.0430839002174],
  [ISongs.BROKEN1]: [252000, 1246.6213151927548],
  [ISongs.BROKEN2]: [255000, 1298.866213151939],
  [ISongs.FAIL]: [258000, 367.1655328798238],
  [ISongs.BLOCK_LAND]: [260000, 268.48072562358993],
  [ISongs.EFFECT_MOVE1]: [262000, 1275.6462585034],
  [ISongs.EFFECT_MOVE2]: [265000, 1597.823129251708],
  [ISongs.POWER_UP]: [268000, 1420.770975056712],
  [ISongs.INVINCIBLE_START]: [271000, 1577.5056689342364],
  [ISongs.INVINCIBLE_END]: [274000, 689.3424036281317],
  [ISongs.ITEM_ICON1]: [276000, 1176.9614512471662],
  [ISongs.ITEM_ICON2]: [279000, 1554.2857142856974],
  [ISongs.PUSH_DISP1]: [282000, 529.705215419483],
  [ISongs.PUSH_DISP2]: [284000, 1220.4988662131768],
  [ISongs.INIT_DISP]: [287000, 2802.358276643986],
  [ISongs.MULTIPLIER_SPLITS]: [291000, 2114.4671201814162],
  [ISongs.THUNDER]: [295000, 3034.557823129262],
  [ISongs.MULTIPLIER_GATGERING]: [300000, 2674.6485260770783],
  [ISongs.MULTIPLIER_RESULT_DISPLAY]: [304000, 2291.5192743764123],
  [ISongs.SHOT_COUNT_UP_LOOP1]: [308000, 123.3560090702781, true],
  [ISongs.SHOT_COUNT_UP_LOOP2]: [310000, 119.00226757370547, true],
  [ISongs.SHOT_COUNT_UP_LOOP3]: [312000, 1454.1496598639583, true],
  [ISongs.HEARTBEAT_LOOP]: [315000, 663.2199546],
  [ISongs.RECOUNT_UP]: [317000, 3983.6734693877816],
  [ISongs.SHOT_FIRE]: [322000, 2080.997732426283],
  [ISongs.BUILD_UP_POWER1]: [326000, 3104.217687074822],
  [ISongs.BUILD_UP_POWER2]: [331000, 3382.8571428571195],
  [ISongs.BUILD_UP_POWER3]: [336000, 4218.775510204068],
  [ISongs.HAMMER_THROWING]: [342000, 4955.918367346953],
  [ISongs.BOOMERANG]: [348000, 427.57369614514573],
  [ISongs.SmallWin]: [350000, 1786.4852607709736],
  [ISongs.MediumWin]: [353000, 1783.5827664399062],
  [ISongs.HighWin]: [356000, 2677.5510204081456],
  [ISongs.BuyButton]: [360000, 666.6666666666856],
  [ISongs.SFX_SM_CountUp_End]: [362000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [364000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [366000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [368000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [370000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [372000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [374000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [376000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [378000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [380000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [382000, 151.02040816327644],
  [ISongs.SFX_WIN_FeatureTrigger]: [384000, 879.8639455782222],
  [ISongs.SFX_WIN_MAX]: [386000, 4340.680272108841],
  [ISongs.Win_Big]: [392000, 1622.358276643979],
  [ISongs.Win_Epic]: [395000, 1862.2675736961583],
  [ISongs.Win_Great]: [398000, 2118.412698412726],
  [ISongs.Win_Mega]: [402000, 1696.190476190452],
  [ISongs.SFX_phoenix]: [405000, 4041.723356009072],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.3,
  [ISongs.BGM_BG_Melo_Loop]: 0.3,
  [ISongs.BGM_LIGHT_Loop]: 0.3,
  [ISongs.LIGHT_ExitPopup]: 0.4,
  [ISongs.BGM_SHOT_Loop]: 0.3,
  [ISongs.SHOT_ExitPopup]: 0.4,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.SWING]: 0.5,
  [ISongs.BROKEN1]: 0.5,
  [ISongs.BROKEN2]: 0.5,
  [ISongs.FAIL]: 0.5,
  [ISongs.BLOCK_LAND]: 0.5,
  [ISongs.EFFECT_MOVE1]: 0.5,
  [ISongs.EFFECT_MOVE2]: 0.5,
  [ISongs.POWER_UP]: 0.5,
  [ISongs.INVINCIBLE_START]: 0.5,
  [ISongs.INVINCIBLE_END]: 0.5,
  [ISongs.ITEM_ICON1]: 0.5,
  [ISongs.ITEM_ICON2]: 0.5,
  [ISongs.PUSH_DISP1]: 0.5,
  [ISongs.PUSH_DISP2]: 0.5,
  [ISongs.INIT_DISP]: 0.5,
  [ISongs.MULTIPLIER_SPLITS]: 0.5,
  [ISongs.THUNDER]: 0.5,
  [ISongs.MULTIPLIER_GATGERING]: 0.5,
  [ISongs.MULTIPLIER_RESULT_DISPLAY]: 0.5,
  [ISongs.SHOT_COUNT_UP_LOOP1]: 0.5,
  [ISongs.SHOT_COUNT_UP_LOOP2]: 0.5,
  [ISongs.SHOT_COUNT_UP_LOOP3]: 0.5,
  [ISongs.HEARTBEAT_LOOP]: 0.5,
  [ISongs.RECOUNT_UP]: 0.5,
  [ISongs.SHOT_FIRE]: 0.5,
  [ISongs.BUILD_UP_POWER1]: 0.5,
  [ISongs.BUILD_UP_POWER2]: 0.5,
  [ISongs.BUILD_UP_POWER3]: 0.5,
  [ISongs.HAMMER_THROWING]: 0.5,
  [ISongs.BOOMERANG]: 0.5,
  [ISongs.SmallWin]: 0.5,
  [ISongs.MediumWin]: 0.5,
  [ISongs.HighWin]: 0.5,
  [ISongs.BuyButton]: 0.5,
  [ISongs.SFX_SM_CountUp_End]: 0.5,
  [ISongs.SFX_SM_CountUp_Loop]: 0.5,
  [ISongs.SFX_UI_AutoSpin]: 0.5,
  [ISongs.SFX_UI_BetChange]: 0.5,
  [ISongs.SFX_UI_Close]: 0.5,
  [ISongs.SFX_UI_General]: 0.5,
  [ISongs.SFX_UI_Hover]: 0.5,
  [ISongs.SFX_UI_MaxBet]: 0.5,
  [ISongs.SFX_UI_MenuOpen]: 0.5,
  [ISongs.SFX_UI_SpinStart]: 0.5,
  [ISongs.SFX_UI_SpinStop]: 0.5,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.5,
  [ISongs.SFX_WIN_MAX]: 0.5,
  [ISongs.Win_Big]: 0.5,
  [ISongs.Win_Mega]: 0.5,
  [ISongs.Win_Great]: 0.5,
  [ISongs.Win_Epic]: 0.5,
  [ISongs.SFX_phoenix]: 0.5,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
