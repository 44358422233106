import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EnterProps, EventTypes, GameMode, GeneratedReelSet, ISettledBet, UserBonus } from '../../global.d';
import {
  baseGameCountUp,
  setBetAmount,
  setBetResult,
  setBonusGameDataRequested,
  setBrokenGame,
  setCurrentBonus,
  setGameHistory,
  setGuaranteeAmount,
  setIsBuyFeaturePurchased,
  setIsFreeSpinsWin,
  setIsGameOver,
  setIsProceedToGame,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsSuperPowerEnabled,
  setLastRegularWinAmount,
  setLightningBonusTotalWin,
  setSkipIntroScreen,
  setSlotConfig,
  setSpecialShotBonusTotalWin,
  setUserBalance,
  setWPower,
  setWinAmount,
  setWinCoinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { WinStages, eventManager } from '../../slotMachine/config';
import { Icon, ThorMovements, ThorPowers, ThorRewards } from '../../slotMachine/d';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { getBetResult, getCascadeSlots, getWinStage, saveReelPosition, updateCoinValueAfterBonuses } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  private nextCascade(): void {
    eventManager.once(EventTypes.RESET_REELS_ANIMATION_END, () => {
      if (setIsSuperPowerEnabled()) {
        eventManager.once(EventTypes.THOR_AFTER_SUPER_POWER_IN, () => {
          setIsSuperPowerEnabled(false);
          this.startNextCascade();
        });
      } else {
        this.startNextCascade();
      }
    });

    eventManager.emit(EventTypes.RESET_REELS_ANIMATION_START, 0);
  }

  private startNextCascade(): void {
    const { generatedReelSet } = getBetResult(setBetResult()).bet.data.features;
    const slotIds = generatedReelSet.map((e) => ({
      slotId: e.iconId as SlotId,
      rewardName: e.rewardName as ThorRewards,
    }));

    const cascadeIcons = getCascadeSlots({
      slotIds,
    });
    if (!cascadeIcons.length) {
      setIsGameOver(true);
      eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
      setTimeout(() => {
        AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
        this.thorGameOver();
      }, 500);
    } else {
      const currentGuaranteeAmount = setGuaranteeAmount();
      const wPower = setWPower();
      if (wPower) {
        this.thorWPowerHit(generatedReelSet, cascadeIcons);
      } else if (currentGuaranteeAmount) {
        this.thorGuaranteeHit(generatedReelSet, cascadeIcons);
      } else {
        this.thorHit(generatedReelSet, cascadeIcons);
      }
    }
  }

  public override enterInitState(_prevState: States): void {
    if (!setSkipIntroScreen()) {
      Logic.the.changeState(States.INTRO);
      return;
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.BROKEN_GAME);
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.init();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProceedToGame(true);
    const bonus = setCurrentBonus();
    SlotMachine.the().onBrokenGame(bonus);
    Logic.the.changeState(States.TRANSITION);
    Logic.the.changeGameMode(bonus.gameMode, { bonus, immediate: true });
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }

    // workaround when we buy feature durring offline mode
    if (
      prevState === States.INIT ||
      prevState === States.INTRO ||
      prevState === States.BROKEN_GAME ||
      setIsBuyFeaturePurchased()
    ) {
      // const debug = new Debug();
      // debug.x = 800;
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
      AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
    }, 30000);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    Logic.the.changeState(States.CASCADE_PRESENTATION);
  }

  public override enterCascadeState(_prevState: States): void {
    const { generatedReelSet } = getBetResult(setBetResult()).bet.data.features;
    const slotIds = generatedReelSet.map((e) => ({
      slotId: e.iconId as SlotId,
      rewardName: e.rewardName as ThorRewards,
    }));
    eventManager.on(EventTypes.NEXT_CASCADE, this.nextCascade.bind(this));
    const cascadeIcons = getCascadeSlots({
      slotIds,
    });
    this.thorHit(generatedReelSet, cascadeIcons);
  }

  public override exitCascadeState(_nextState: States): void {
    eventManager.removeAllListeners(EventTypes.NEXT_CASCADE);
    eventManager.removeAllListeners(EventTypes.RESET_REELS_ANIMATION_END);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setWinAmount(winCoinAmount);
        setLastRegularWinAmount(winCoinAmount);
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      setWinAmount(winCoinAmount);
      setLastRegularWinAmount(winCoinAmount);
      setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
    }
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    if (result.bet.data.bonuses.length > 0) {
      setIsFreeSpinsWin(true);
      const [bonus] = result.bet.data.bonuses;
      setCurrentBonus({
        ...bonus!,
        isActive: true,
        isFinished: false,
        currentRound: 0,
      });

      if (bonus!.data.specialShotValues?.length) {
        AudioApi.play({ type: ISongs.SFX_WIN_FeatureTrigger, stopPrev: true });
        setTimeout(() => {
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.SPECIAL_SHOT_BONUS, {
            bonus: bonus as UserBonus,
          });
        }, 1000);
        this.resetBetResult();
        return;
      }
      AudioApi.play({ type: ISongs.SFX_WIN_FeatureTrigger, stopPrev: true });
      setTimeout(() => {
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.LIGHTNING_BONUS, {
          bonus: bonus as UserBonus,
        });
      }, 1000);
      this.resetBetResult();
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  private resetBetResult(): void {
    const betResult = getBetResult(setBetResult());
    const newBetResult = JSON.parse(JSON.stringify(betResult)) as ISettledBet;
    newBetResult.bet.data.features.generatedReelSet = [];
    setBetResult(newBetResult);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    updateCoinValueAfterBonuses();
    AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
    eventManager.emit(EventTypes.SHOW_WIN_LABEL);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    if (prevGameMode === GameMode.LIGHTNING_BONUS) {
      if (setLightningBonusTotalWin() > 0) {
        const totalWin = setBrokenGame() ? setLightningBonusTotalWin() : setWinAmount() + setLightningBonusTotalWin();
        setWinAmount(totalWin);
        eventManager.emit(EventTypes.UPDATE_WIN_VALUE, totalWin);
      }
    }
    if (prevGameMode === GameMode.SPECIAL_SHOT_BONUS) {
      if (setSpecialShotBonusTotalWin() > 0) {
        const totalWin = setBrokenGame()
          ? setSpecialShotBonusTotalWin()
          : setWinAmount() + setSpecialShotBonusTotalWin();
        setWinAmount(totalWin);
        eventManager.emit(EventTypes.UPDATE_WIN_VALUE, totalWin);
      }
    }
    if (setBrokenGame()) setBrokenGame(false);
    Logic.the.changeState(States.IDLE);
    eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
    setBonusGameDataRequested(false);
  }

  public override exitController(_nextGameMode: GameMode): void {
    AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    result.bet.data.features.generatedReelSet.reverse();
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setWinCoinAmount(result.bet.result.winCoinAmount);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    setBetResult(result);
  }

  private handleHistory(skipSoundChange: boolean): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    const isThirdWinInRow = lastThreeSpins.every((val) => Boolean(val));
    const isThirdLoseInRow = lastThreeSpins.every((val) => !val);
    const isWinFiveTimesBiggerThanBet = setBetAmount() * 5 <= win;

    if (!skipSoundChange) {
      if (isWinFiveTimesBiggerThanBet || isThirdWinInRow) {
        AudioApi.fadeOut(500, ISongs.BGM_BG_Base_Loop);
        AudioApi.fadeIn(500, ISongs.BGM_BG_Melo_Loop);
      }

      if (isThirdLoseInRow) {
        AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
        AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
      }
    }

    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
    saveReelPosition(betResult.bet.result.reelPositions);
  }

  private thorHit(betResult: GeneratedReelSet[], icons: Icon[]): void {
    eventManager.emit(EventTypes.THOR_ATTACK, ThorMovements.attack, ThorMovements.idle);
    eventManager.once(EventTypes.THOR_AFTER_ATTACK, () => {
      const currentBlockToBreak = betResult[betResult.length - 1] as GeneratedReelSet;
      if (betResult.length && !currentBlockToBreak.isLost) {
        this.checkThorRewards(currentBlockToBreak.rewardName, icons);
      } else {
        setIsGameOver(true);
        eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        setTimeout(() => {
          AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
          this.thorGameOver();
        }, 500);
      }
    });
  }

  private thorGameOver(): void {
    setGuaranteeAmount(0);
    setIsSuperPowerEnabled(false);
    setWPower(false);
    const winCoinAmount = setWinCoinAmount();
    this.resetBetResult();
    if (winCoinAmount) {
      if (winCoinAmount > baseGameCountUp()) {
        eventManager.emit(
          EventTypes.START_TRACK_ANIMATION,
          { x: 180, y: -200 },
          {
            x: 0,
            y: 300,
          },
          500,
          () => {
            AudioApi.play({
              type: ISongs.EFFECT_MOVE1,
              stopPrev: true,
            });

            eventManager.emit(EventTypes.START_COUNT_UP, baseGameCountUp(), winCoinAmount, 0);
            eventManager.emit(EventTypes.THOR_IDLE);
            setTimeout(() => {
              Logic.the.changeState(States.AFTER_WIN);
              baseGameCountUp(0);
            }, 2000);
          },
        );
      } else {
        eventManager.emit(EventTypes.THOR_IDLE);
        setTimeout(() => {
          Logic.the.changeState(States.AFTER_WIN);
          baseGameCountUp(0);
        }, 1000);
      }
    } else {
      eventManager.emit(EventTypes.THOR_IDLE);
      setTimeout(() => {
        Logic.the.changeState(States.AFTER_WIN);
        baseGameCountUp(0);
      }, 0);
    }
  }

  private thorWPowerHit(betResult: GeneratedReelSet[], icons: Icon[]): void {
    const currentGuaranteeAmount = setGuaranteeAmount();
    let leftAmount = 0;
    if (currentGuaranteeAmount) {
      leftAmount = currentGuaranteeAmount - 1;
      setGuaranteeAmount(leftAmount);
    }
    setTimeout(() => {
      eventManager.emit(
        EventTypes.THOR_ATTACK,
        currentGuaranteeAmount ? ThorMovements.guaranteePlusWPowerAttack : ThorMovements.wPowerAttack,
        currentGuaranteeAmount ? ThorMovements.guaranteePlusWPowerLoop : ThorMovements.wPowerIdle,
      );
    }, 0);
    eventManager.once(EventTypes.THOR_AFTER_ATTACK, () => {
      const currentBlockToBreak = betResult[betResult.length - 1] as GeneratedReelSet;
      if (!leftAmount && currentGuaranteeAmount) {
        eventManager.emit(EventTypes.THOR_GUARANTEE_OUT);
        eventManager.emit(EventTypes.UPDATE_GAME_PROGRESS_ICON, 'invincible_icon', false);
      }
      if (betResult.length && !currentBlockToBreak.isLost) {
        this.checkThorRewards((currentBlockToBreak as GeneratedReelSet).rewardName, icons);
      } else {
        setIsGameOver(true);
        eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        setTimeout(() => {
          AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
          this.thorGameOver();
        }, 500);
      }
    });
  }

  private thorGuaranteeHit(betResult: GeneratedReelSet[], icons: Icon[]): void {
    const guaranteeAmount = setGuaranteeAmount();
    const leftAmount = guaranteeAmount - 1;
    setTimeout(() => {
      eventManager.emit(EventTypes.THOR_ATTACK, ThorMovements.guaranteeAttack, ThorMovements.guaranteeIdle);
    }, 0);
    setGuaranteeAmount(leftAmount);
    eventManager.once(EventTypes.THOR_AFTER_ATTACK, () => {
      const currentBlockToBreak = betResult[betResult.length - 1] as GeneratedReelSet;

      if (!leftAmount) {
        eventManager.emit(EventTypes.THOR_GUARANTEE_OUT);
        eventManager.emit(EventTypes.UPDATE_GAME_PROGRESS_ICON, 'invincible_icon', false);
      }
      if (betResult.length && !currentBlockToBreak.isLost) {
        this.checkThorRewards(currentBlockToBreak.rewardName, icons);
      } else {
        setIsGameOver(true);
        eventManager.emit(EventTypes.RESET_THOR_ANIMATION_SPEED);
        setTimeout(() => {
          AudioApi.play({ type: ISongs.FAIL, stopPrev: true });
          this.thorGameOver();
        }, 500);
      }
    });
  }

  private checkThorRewards(reward: ThorRewards | null, icons: Icon[]): void {
    const newReward = reward?.includes('MULTIPLIER') || reward?.includes('GUARANTEE') ? reward.split('_')[0] : reward;
    switch (newReward) {
      case 'GUARANTEE':
        this.updateGuaranteeWin(reward as string, icons);
        break;
      case 'D_W_POWER':
        this.updatePowers(reward as ThorPowers, icons);
        break;
      default:
        this.startCascadeWinAnimation(icons, false);
        break;
    }
  }

  private updateGuaranteeWin(guarantee: string, icons: Icon[]): void {
    const guaranteeAmount = +guarantee.split('_')[1]! as number;
    const currentGuaranteeAmount = setGuaranteeAmount();
    setGuaranteeAmount(currentGuaranteeAmount + guaranteeAmount);
    setIsSuperPowerEnabled(true);
    eventManager.emit(EventTypes.THOR_GUARANTEE_IN);
    eventManager.emit(EventTypes.THOR_GUARANTEE_IDLE);
    this.startCascadeWinAnimation(icons, true);
  }

  private updatePowers(_powers: ThorPowers, icons: Icon[]): void {
    setWPower(true);
    setIsSuperPowerEnabled(true);
    const currentGuaranteeAmount = setGuaranteeAmount();
    if (currentGuaranteeAmount) {
      setGuaranteeAmount(currentGuaranteeAmount + 1);
    }
    eventManager.emit(EventTypes.THOR_W_POWER_IN);
    this.startCascadeWinAnimation(icons, true);
  }

  private startCascadeWinAnimation = (icons: Icon[], superPower: boolean) => {
    eventManager.emit(EventTypes.START_CASCADE_WIN_ANIMATION, icons, superPower);
    eventManager.emit(EventTypes.UPDATE_GAME_PROGRESS);
  };
}
